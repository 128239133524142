import React, { Fragment } from "react";
import appImages from "../../assets";
import "../../styles/layoutSass/home/base.scss";
import "../../styles/layoutSass/about/base.scss";
import appColors from "../../utils/themes/colorThemes";
import {
  ArrowBackIos,
  ArrowForwardIos,
  ChevronRight,
  DoubleArrow,
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material";

import Slider from "./LogoSlider";
import { useState } from "react";
import { useNavigate } from "react-router";

export const AboutUs = () => {
  const clientLogos = [
    appImages.logos.nutanixshort,
    appImages.logos.redhat,
    appImages.logos.vmware,
  ];
  // const [id, setId] = useState(0);
  const getId = () => {
    const randId = Math.random().toFixed(2);
    return randId;
  };
  const navigate = useNavigate();
  const accordianData = [
    {
      heading: "Find and get solutions for your success",
      accordCont: `Experience streamlined IT infrastructure with reduced complexity and enhanced performance. Our team designs and implements highly available, scalable, and flexible solutions tailored to your specific needs`,
    },
    {
      heading: "Get right knowledge of your passion",
      accordCont: `Seamlessly transition your critical business applications and data to new platforms with our expert assistance, ensuring minimal downtime and uninterrupted operations`,
    },
    {
      heading: "Find the person sharing the same position as you",
      accordCont: `Equip your organization with a comprehensive plan and solution, as we implement a robust disaster recovery strategy customized to your specific requirements`,
    },
    {
      heading: "Find right mentor and coach as per your goal and passion ",
      accordCont: `Revolutionize your IT operations with automation, freeing up valuable time and resources to focus on strategic initiatives that drive business growth`,
    },
    {
      heading:
        "Find right collaborator and opportunities as per your goal and passion",
      accordCont: `To foster collaboration, enhance communication, and promote the overall effectiveness and well-being of team members`,
    },
  ];

  const [accordIndex, setAccordIndex] = useState(0);
  const certiImages = Object.entries(appImages.certifications);

  const [certiCarInd, setCertiCarInd] = useState({
    startInd: 0,
    noOfEl: 7,
  });
  const [sliderImages, setSliderImages] = useState(
    certiImages.slice(certiCarInd, 7)
  );
  // const [blogList, setBlogList] = useState([
  //   {
  //     blogName: "Evolving program Manage Office for modern",
  //     blogDesc: "printer took a galley of type and scrambled it to make a type",
  //     blogDate: "13 June 2021",
  //     blogComments: 5,
  //   },
  //   {
  //     blogName: "Design Decision with Bea Fietler Unstoppable",
  //     blogDesc: "printer took a galley of type and scrambled it to make a type",
  //     blogDate: "15 June 2021",
  //     blogComments: 3,
  //   },
  //   {
  //     blogName: "Pain free Workflow Issue Reporting Resolution",
  //     blogDesc: "printer took a galley of type and scrambled it to make a type",
  //     blogDate: "16 June 2021",
  //     blogComments: 15,
  //   },
  // ]);

  return (
    <div className="mainContentSection">
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"About Us"}</h1>

          <p className="subNavAbout mt10">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; About
          </p>
        </div>
      </div>
      <div className="centerGrid80 aboutWhoWeAreCont mt20">
        <div className="whoWeHeadCont">
          <p className="whoWeHeadTxt">Delivering </p>
          <p className="whoWeHeadTxt" style={{ color: appColors.primaryColor }}>
            Tech Excellence
          </p>
          <p className="whoWeHeadTxt">With The Customer at our Core</p>
          <div className="whoWeUnderline" />
        </div>
        {/* <div className="whoWeContCont">
          <p className="whoWeContHeadTxt">
            Building IT Solutions for the Future: avancerPI Solutions
          </p>
          <p className="whoWeContTxt">
            At avancerPI Solutions, we are a highly experienced team of
            Consulting Architects, Developers, and Automation Engineers. Our
            expertise lies in the design and consultation of IT solutions, and
            we have a proven track record of delivering enterprise-grade
            projects across the globe. Our services include the design and
            implementation of Datacenter Virtualizations, HCI Solutions,
            Workload Migrations, Business Continuity and Disaster Recovery
            Solutions, Hybrid Cloud Solutions and Integrations, Custom solutions
            for Datacenters, App Modernization, and Web Development. We approach
            each engagement, big or small, with a consultative mindset,
            understanding our clients' actual requirements and desired outcomes.
            We then build solutions that address these requirements while
            adhering to our design principles of addressing business and
            technical requirements, following design constraints, validating
            assumptions, and mitigating risks.
            <br />
            <br />
            Our team has helped clients transform their IT operations through
            consulting workshops, becoming a trusted advisor in their journey to
            achieve cloud and agility. In the design phase, we work closely with
            stakeholders and subject matter experts to understand project
            objectives and requirements. Our documentation includes a
            comprehensive analysis of the current state, conceptual, logical,
            and physical design, requirements, constraints, assumptions, risks,
            and design decisions. Our focus is always on achieving high
            availability, security, manageability, scalability, and simplicity.
          </p>
        </div> */}
      </div>

      <div className="homeTestimonialsCont aboutAddInfoCont mt20">
        <div className="homeTestimonialsInnCont centerGrid80 aboutAddInfoContContent mt15 mb15">
          <div className="homeTestiHeadCont">
            <p
              className="headTxtSmall smallBoldTitles"
              style={{ textAlign: "left" }}
            >
              <b>About avancerPI Solutions </b>
            </p>
            <p
              className="whoWeHeadTxt"
              style={{ marginTop: 10, color: appColors.whiteColor }}
            >
              Unleashing the Power of IT for Your Business
            </p>
            <p className="whoWeContTxt" style={{ color: appColors.whiteColor }}>
              At avancerPI Solutions, we understand the vital role that
              technology plays in the success of your business. That's why we
              offer a comprehensive suite of IT solutions that are tailored to
              your specific needs. Our services cover areas such as Datacenter
              Virtualizations, HCI Solutions, Workload Migrations, Business
              Continuity and Disaster Recovery Solutions, Hybrid Cloud Solutions
              and Integrations, Custom solutions for Datacenters, App
              Modernization, and Web Development. With a team of experts who
              have a proven track record of delivering high-quality solutions,
              we are dedicated to helping you unleash the full potential of IT
              for your business. Whether you need to modernize your apps,
              migrate your workloads, or build a hybrid cloud solution, we have
              the expertise and experience to deliver the results you're looking
              for.
            </p>

 
          </div>

          <div className="homeTestiContCont aboutInfosCont">
            {accordianData.map((accord, i) => {
              return (
                <Fragment>
                  <div
                    className="aboutUsAccorCont"
                    onClick={() => {
                      setAccordIndex(i);
                    }}
                  >
                    <p>{accord?.heading}</p>
                    {accordIndex !== i ? (
                      <ArrowDropDown className="aboutAccArrow" />
                    ) : (
                      <ArrowDropUp className="aboutAccArrow" />
                    )}
                  </div>
                  {accordIndex === i && (
                    <div className="accordContCont">
                      <p>{accord?.accordCont}</p>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>

      <div className="centerGrid80 aboutWhoWeAreCont">
        <div className="whoWeHeadCont">
          <p
            className="headTxtSmall smallBoldTitles"
            style={{ textAlign: "left", marginTop: 25 }}
          >
            <b>About avancerPI Solutions </b>
          </p>

          <p className="whoWeHeadTxt">
            Our mission is to make your business better through best in
            technology
          </p>

          <div className="whoWeUnderline" />
          <p className="whoWeContTxt" style={{ marginTop: 20 }}>
            Our mission is to empower our clients through exceptional
            professional services, by providing best-in-class, innovative hybrid
            cloud solutions, continuously improving our approach and being a
            trusted partner in navigating the ever-evolving landscape of
            technology to deliver measurable business value.
          </p>
        </div>
        <div className="aboutCertBannerCont">
          <img
            alt="avancerCertifications"
            src={appImages.aboutUsImgs.certificationBanner}
            className="aboutCertBanner"
          />
        </div>
      </div>

      <div className="centerGrid60 aboutCertCarCont mt10" key={getId()}>
        <ArrowBackIos
          fontSize="small"
          className="certiCarIcon"
          onClick={() => {
            if (Math.sign(certiCarInd.startInd) !== -1) {
              let certImgs = [...certiImages];
              certImgs = certImgs.splice(
                certiCarInd.startInd - 1,
                certiCarInd.noOfEl
              );

              if (certImgs.length === certiCarInd.noOfEl) {
                // setId(getId());
                document.getElementsByClassName(
                  "certiCarIcon"
                )[1].style.opacity = 1;
                setSliderImages([...certImgs]);
                setCertiCarInd({
                  ...certiCarInd,
                  startInd: certiCarInd.startInd - 1,
                });
              } else {
                document.getElementsByClassName(
                  "certiCarIcon"
                )[0].style.opacity = 0;
              }
            } else {
              document.getElementsByClassName(
                "certiCarIcon"
              )[0].style.opacity = 0;
            }
          }}
        />

        {[...sliderImages].map((img, i) => {
          return (
            <img
              key={i}
              alt="avancerCertificates"
              src={img[1]}
              className="aboutSingleCertiImg"
            />
          );
        })}
        <ArrowForwardIos
          fontSize="small"
          className="certiCarIcon"
          onClick={() => {
            let certImgs = [...certiImages];
            certImgs = certImgs.splice(
              certiCarInd.startInd + 1,
              certiCarInd.noOfEl
            );

            if (certImgs.length === certiCarInd.noOfEl) {
              // setId(getId());

              document.getElementsByClassName(
                "certiCarIcon"
              )[0].style.opacity = 1;
              setSliderImages([...certImgs]);
              setCertiCarInd({
                ...certiCarInd,
                startInd: certiCarInd.startInd + 1,
              });
            } else {
              document.getElementsByClassName(
                "certiCarIcon"
              )[1].style.opacity = 0;
            }
          }}
        />
      </div>
      {/* <div className="homeBlogsCont">
        <p className="headTxtSmall smallBoldTitles">
          <b>OUR BLOGS </b>
        </p>
        <p
          className="aboutHeadingHome textAlignCenter"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          Trending Business Consulting
          <br />
          Articles {"&"} Tips
        </p>
        <div className="blogCardsContHome centerGrid60">
          {blogList.map((blog, i) => {
            return (
              <div className="homeBlogCard" key={i}>
                <img
                  src="https://www.jobboardfinder.com/news/wp-content/uploads/2021/07/team.jpg"
                  className="blogCardImageHome"
                  alt="blogImg"
                />
                <p className="blogTitleHome">{blog.blogName}</p>
                <p className="homeBlogDesc">{blog.blogDesc}</p>
                <div className="homeBlogTimeComCont">
                  <EventNote
                    style={{
                      fontSize: 12.5,
                    }}
                    className="blogEveIcon"
                  />
                  <p className="homeBlogDesc" style={{ paddingLeft: 3.5 }}>
                    {blog.blogDate}
                  </p>
                  <Comment
                    style={{
                      fontSize: 12.5,
                    }}
                    className="blogEveIcon"
                  />
                  <p className="homeBlogDesc" style={{ paddingLeft: 2.5 }}>
                    {blog.blogComments}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
      <div className="clientSliderContHome mt20">
        <p className="headTxtSmall smallBoldTitles">
          <b>OUR TECHNOLOGY PARTNERS </b>
        </p>
        <p
          style={{ marginTop: 10 }}
          className="aboutHeadingHome textAlignCenter"
        >
          Committed to Helping
          <br />
          Our technology partners Succeed
        </p>

        <div className="customMarquee">
          <div className="marqueeInner">
            <Slider data={clientLogos} />
            {/* <marquee>
              {clientLogos.map((client, i) => {
                return (
                  <img
                    key={i}
                    className="logoImg"
                    src={client}
                    alt="avancerClients"
                  />
                );
              })}
            </marquee> */}
          </div>
        </div>
      </div>
      <div className="homeFormsCont mt20">
        <p className="headTxtSmall smallBoldTitles" style={{ marginTop: -100 }}>
          <b>JOIN US</b>
        </p>

        <div className="blogCardsContHome centerGrid60 mt15">
          <div className="homeFormsCard">
            <div className="homeFormsInnCard">
              <p className="formTitleHome">
                Let's Work Together
                <br /> on your next project
              </p>
              <div
                className="homeFormLearnMore"
                onClick={() => {
                  navigate("/about");
                }}
              >
                <p className="learnMoreHomeAbt">Join Now</p>
                <DoubleArrow
                  style={{ fontSize: 15 }}
                  className="learnMoreArrForm"
                />
              </div>
            </div>
          </div>
          <div className="homeFormsCard">
            <div className="homeFormsInnCard">
              <p className="formTitleHome">
                Request to call back <br />
              </p>

              <div
                className="homeFormLearnMore"
                onClick={() => {
                  navigate("/contact");
                }}
              >
                <p className="learnMoreHomeAbt">Get a quote</p>
                <DoubleArrow
                  style={{ fontSize: 15 }}
                  className="learnMoreArrForm"
                />
              </div>
            </div>
          </div>
          <div className="homeFormsCard">
            <div className="homeFormsInnCard">
              <p className="formTitleHome">
                Build a learning and rewarding Career <br />
                With avancerPI Solutions
              </p>
              <div
                className="homeFormLearnMore"
                onClick={() => {
                  navigate("/career");
                }}
              >
                <p className="learnMoreHomeAbt">View jobs</p>
                <DoubleArrow
                  style={{ fontSize: 15 }}
                  className="learnMoreArrForm"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
