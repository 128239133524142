import React from "react";
// import { connect } from 'react-redux'
import appImages from "../../assets";
import "../../styles/componentSass/footer/_footer.scss";

//icons
import { ContactPhone, Email, LocationOn, WhatsApp } from "@mui/icons-material";

import { LinkedIn } from "@mui/icons-material";
import { useNavigate } from "react-router";
import appConstants from "../../utils/appStrings/appConstants";
import openWhatsApp, {
  handlePhoneNumberClick,
} from "../../utils/commonFunctions/openWhatsapp";

export const Footer = (props) => {
  const navigate = useNavigate();

  const handleSendEmail = () => {
    const email = appConstants.mail; // Replace with the recipient's email address
    const subject = "Request for quote"; // Replace with the desired subject line
    const body = "Hi avancerPI solutions, Shall we connect!"; // Replace with the desired email body

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  return (
    <div className="footerContainer">
      <div className="footerInnerContainer centerGrid80">
        <div className="footerContentsWrapper">
          <div className="footersectionSeperation">
            <div className="footerSection1">
              <ul className="footerUl">
                <li>
                  <h1>About</h1>
                </li>
                <p className="aboutFooterContent">
                  Certainly! we are happy to help you with our services.
                </p>
                <span
                  className="mailAndContactInfo cp"
                  onClick={handlePhoneNumberClick}
                >
                  <ContactPhone />
                  <p> &nbsp;&nbsp; {appConstants.phoneNumber}</p>
                </span>
                <span
                  className="mailAndContactInfo cp"
                  onClick={handleSendEmail}
                >
                  <Email />
                  <p> &nbsp;&nbsp;{appConstants.mail}</p>
                </span>
                <span
                  className="mailAndContactInfo cp"
                  style={{
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                  onClick={() => {
                    window.open(appConstants.mapAddress, "_blank");
                  }}
                >
                  <LocationOn />
                  avancerPI Solutions, Splendid Plaza, LG-07,Lower Ground Floor
                  <br />
                  26 Wheeler Road, Cox Town, Bangalore - 560005
                </span>
              </ul>
            </div>
            <div className="footerSection2">
              <ul className="footerUl">
                <li>
                  <h1>Links</h1>
                </li>
                <li
                  className="listWithLogoStyle"
                  style={{ marginTop: 15, cursor: "pointer" }}
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Home</p>
                </li>
                <li
                  className="listWithLogoStyle"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/about");
                  }}
                >
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">About us</p>
                </li>
                <li
                  className="listWithLogoStyle"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/hci");
                  }}
                >
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Services</p>
                </li>
                <li
                  className="listWithLogoStyle"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/Career");
                  }}
                >
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Career</p>
                </li>
                <li
                  className="listWithLogoStyle"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/Contact");
                  }}
                >
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Contact</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="segmentation" />
          <div className="footersectionSeperation">
            <div className="footerSection3">
              <ul className="footerUl">
                <li>
                  <h1>Explore</h1>
                </li>
                <li
                  className="listWithLogoStyle"
                  style={{ marginTop: 15, cursor: "pointer" }}
                  onClick={() => {
                    navigate("/hci");
                  }}
                >
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">HCI Data Center</p>
                </li>
                <li
                  className="listWithLogoStyle"
                  onClick={() => {
                    navigate("/WorkLoadMigration");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Workload Migration</p>
                </li>
                <li
                  className="listWithLogoStyle"
                  onClick={() => {
                    navigate("/DisasterRecovery");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Disaster Recovery</p>
                </li>
                <li
                  className="listWithLogoStyle"
                  onClick={() => {
                    navigate("/CloudAutomation");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">Cloud Automation</p>
                </li>
                <li
                  className="listWithLogoStyle cp"
                  onClick={() => {
                    navigate("/AppDevelopment");
                  }}
                >
                  <img src={appImages.appImages.appArrowLogo} alt="" />
                  <p className="subServiceName">App Development</p>
                </li>
              </ul>
            </div>
            <div className="footerSection4">
              <ul className="footerUl">
                <li>
                  <h1>Social connections</h1>
                </li>
                {/* <li className="listWithLogoStyle">
                  <Facebook className="headerSocialIcon" />

                  <a href="#" className="subServiceName">
                    Facebook
                  </a>
                </li> */}
                {/* <li className="listWithLogoStyle">
                  <Instagram className="headerSocialIcon" />

                  <a href="#" className="subServiceName">
                    Instagram
                  </a>
                </li> */}
                {/* <li className="listWithLogoStyle">
                  <Twitter className="headerSocialIcon" />

                  <a href="#" className="subServiceName">
                    Twitter{" "}
                  </a>
                </li> */}
                <li
                  className="listWithLogoStyle"
                  onClick={() => {
                    openWhatsApp();
                  }}
                >
                  <WhatsApp className="headerSocialIcon" />
                  <p className="subServiceName">Whatsapp</p>
                </li>
                <li
                  className="listWithLogoStyle"
                  onClick={() => {
                    window.open(appConstants.linkedIn, "_blank");
                  }}
                >
                  <LinkedIn className="headerSocialIcon" />

                  <a href="#" className="subServiceName">
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = (state) => ({})

// const mapDispatchToProps = {}

// export default connect(mapStateToProps, mapDispatchToProps)(Footer)

export default Footer;
