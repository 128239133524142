import React, { Fragment, useState } from "react";
import "../../../styles/layoutSass/home/base.scss";
import "../../../styles/layoutSass/about/base.scss";
import "../../../styles/layoutSass/services/base.scss";
import "../../../styles/layoutSass/home/login/_login.scss";
import "../../../styles/layoutSass/services/_hcitest.scss";

import appColors from "../../../utils/themes/colorThemes";
import appImages from "../../../assets";
import {
  ArrowDropDown,
  CheckCircle,
  ChevronRight,
  LinkedIn,
  LocalPostOffice,
  WarningAmberOutlined,
  WhatsApp,
} from "@mui/icons-material";
import formValidators from "../../../utils/commonFunctions/formValidators";
import Toast from "../../../components/Toast";
import { connect } from "react-redux";
import { createRequestQuote } from "../../../redux/actions/form.actions";
import { ArrowDropUp } from "@material-ui/icons";
import appConstants from "../../../utils/appStrings/appConstants";
import openWhatsApp, {
  handlePhoneNumberClick,
} from "../../../utils/commonFunctions/openWhatsapp";

const initialDetails = {
  name: "",
  email: "",
  mobile: "",
  sub: "",
  message: "",
};
const initialErrors = {
  name: false,
  email: false,
  mobile: "",
  sub: false,
  message: false,
};

const CloudAuto = (props) => {
  const accordianData = [
    {
      title: "Flexibility",
      desc:
        "Hybrid cloud solutions allow organizations to leverage the best features of both private and public cloud environments, offering greater flexibility in deploying and managing workloads",
    },
    {
      title: "Cost efficiency",
      desc:
        "We help organizations optimize their IT spending by choosing the most cost-effective mix of public and private cloud resources",
    },
    {
      title: "Enhanced security",
      desc:
        "Our hybrid cloud solutions incorporate security measures to protect sensitive data and ensure compliance with industry regulations",
    },
    {
      title: "Seamless integration",
      desc:
        "We design and implement hybrid cloud solutions that provide seamless integration between private and public cloud environments",
    },

    {
      title: "Improved IT operations",
      desc:
        "Our cloud automation services help organizations streamline IT processes, reduce manual tasks, and improve resource utilization",
    },
  ];
  const [accordIndex, setAccordIndex] = useState(0);

  const effPoints = [
    {
      title: "Customized solutions",
      desc:
        "We develop tailored hybrid cloud and cloud automation solutions based on your organization's unique requirements and goals",
    },
    {
      title: "Best practices",
      desc:
        "Our team adheres to industry best practices and design considerations to ensure the success of your hybrid cloud implementation",
    },
    {
      title: "End-to-end support",
      desc:
        "We provide end-to-end support throughout the entire process, from initial planning and design to implementation and ongoing management",
    },

    {
      title: "Training and documentation",
      desc:
        "We offer training programs and documentation to equip your IT team with the knowledge and skills to manage and maintain your hybrid cloud environment effectively",
    },
  ];
  const designKeys = [
    {
      title: "Customized cloud architecture : ",
      content:
        "We design private and hybrid cloud solutions that meet your organization's specific needs, goals, and industry standards",
    },
    {
      title: "High availability : ",
      content:
        "We implement redundancy and fault tolerance to ensure maximum uptime for your cloud services",
    },
    {
      title: "Scalability : ",
      content:
        "Our solutions are designed to grow with your business, enabling you to easily add resources as needed",
    },
    {
      title: "Security : ",
      content:
        "We prioritize data protection and incorporate robust security measures, such as encryption, access controls, and network segmentation",
    },
    {
      title: "Cost efficiency : ",
      content:
        "We help organizations optimize their IT spending by choosing the most cost-effective mix of public and private cloud resources",
    },
  ];
  const designUKeys = [
    {
      title: "Comprehensive assessment : ",
      content:
        "Our team conducts a thorough assessment of your existing infrastructure and requirements to develop the best private or hybrid cloud solution for your organization",
    },
    {
      title: "Design principles : ",
      content:
        "We follow the best design principles for cloud environments, ensuring your solution is reliable, secure, and scalable",
    },
    {
      title: "End-to-end support : ",
      content:
        "We provide end-to-end support throughout the entire process, from initial planning and design to implementation and ongoing management",
    },
    {
      title: "Training and documentation : ",
      content:
        "We offer training programs and documentation to equip your IT team with the knowledge and skills to manage and maintain your private or hybrid cloud environment effectively",
    },
  ];

  const blueprintKeys = [
    {
      title: "Streamlined deployment : ",
      content:
        "Our blueprints automate OS deployment, reducing manual tasks and improving efficiency",
    },
    {
      title: "Third-party integration : ",
      content:
        "We integrate the deployment process with third-party tools and services, such as ITSM, security scans, and IPAM",
    },
    {
      title: "Custom business logic : ",
      content:
        "We incorporate your organization's unique business logic into the deployment process, ensuring the solution meets your specific needs",
    },
    {
      title: "Notification and reporting : ",
      content:
        "Our solutions automate user notifications and generate reports to keep stakeholders informed throughout the deployment process",
    },
  ];
  const blueprintUKeys = [
    {
      title: "Tailored blueprints : ",
      content:
        "We develop custom blueprints based on your organization's requirements, infrastructure, and tools",
    },
    {
      title: "Best practices : ",
      content:
        "We integrate the deployment process with third-party tools and services, such as ITSM, security scans, and IPAMOur team follows industry best practices to ensure the success of your OS deployment automation project",
    },
    {
      title: "Continuous improvement : ",
      content:
        "We regularly review and update your blueprints to keep them aligned with changing business needs, industry best practices, and design principles",
    },
  ];
  const blueAppKeys = [
    {
      title: "Streamlined application deployment : ",
      content:
        "Our blueprints automate application deployment, reducing manual tasks and improving efficiency",
    },
    {
      title: "Integration with existing environment : ",
      content:
        "We seamlessly integrate our blueprints with your organization's environment, processes, and tools",
    },
    {
      title: "Custom solutions : ",
      content:
        "We tailor our blueprints to meet your organization's specific requirements and goals",
    },
    {
      title: "Best practices : ",
      content:
        "Our team follows industry best practices to ensure the success of your application deployment automation project",
    },
  ];

  const blueAppUKeys = [
    {
      title: "Customized blueprints : ",
      content:
        "We develop tailored blueprints based on your organization's requirements, infrastructure, and tools",
    },
    {
      title: "Industry best practices: ",
      content:
        "Our team adheres to industry best practices to ensure the success of your application deployment automation project",
    },
    {
      title: "Continuous improvement : ",
      content:
        "We regularly review and update your blueprints to keep them aligned with changing business needs, industry best practices, and design principles",
    },
  ];
  const thirdPartyKeys = [
    {
      title: "Streamlined processes : ",
      content:
        "Our integrations with third-party solutions reduce manual tasks and improve overall efficiency in your IT environment",
    },
    {
      title: "Enhanced collaboration : ",
      content:
        "Integrating with third-party tools and services enables better collaboration across teams and departments",
    },
    {
      title: "Custom solutions : ",
      content:
        "We develop tailored integrations to meet your organization's specific needs and goals",
    },
    {
      title: "Secure and compliant : ",
      content:
        "Our integrations follow industry best practices and ensure compliance with relevant regulations",
    },
  ];

  const thirdPartyUKeys = [
    {
      title: "Customized integrations : ",
      content:
        "We develop tailored integrations based on your organization's requirements, infrastructure, and tools",
    },
    {
      title: "Best practices : ",
      content:
        "Our team follows industry best practices to ensure the success of your third-party integration projects",
    },
    {
      title: "End-to-end support : ",
      content:
        "We provide end-to-end support throughout the entire process, from planning and design to implementation and ongoing management",
    },
  ];

  const customKeys = [
    {
      title: "Customized solutions : ",
      content:
        "We develop tailored IT solutions that meet your organization's specific needs and objectives",
    },
    {
      title: "Scalable and flexible : ",
      content:
        "Our custom solutions are designed to grow and adapt with your business, ensuring long-term success",
    },
    {
      title: "Expertise : ",
      content:
        "Our team of skilled professionals has extensive experience in designing and implementing custom IT solutions",
    },
    {
      title: "Best practices : ",
      content:
        "We follow industry best practices and design principles to ensure the success of your custom solution",
    },
  ];

  const customUKeys = [
    {
      title: "Consultative approach : ",
      content:
        "We work closely with you to understand your organization's needs, goals, and challenges, ensuring the custom solution meets your specific requirements",
    },
    {
      title: "Best practices : ",
      content:
        "Our team adheres to industry best practices and design principles to develop robust, reliable, and scalable custom solutions.",
    },
    {
      title: "Training and documentation : ",
      content:
        "We offer training programs and documentation to equip your IT team with the knowledge and skills to manage and maintain your custom IT solution effectively",
    },
    {
      title: "End-to-end support: : ",
      content:
        "We provide end-to-end support throughout the entire process, from initial planning and design to implementation and ongoing management",
    },
  ];

  const subServices = [
    {
      title: "Design and Build - Private and Hybrid Cloud",
      desc: `We design and build customized private and hybrid cloud
      solutions that align with your organization's unique
      requirements and goals. Our team follows the best design
      principles for cloud environments to ensure high availability,
      scalability, security, and cost efficiency`,
      keys: designKeys,
      app: designUKeys,
    },
    {
      title: "Develop Blueprints for OS Deployment Automation",
      desc: `We create blueprints to automate OS deployment, including
      end-to-end automation, third-party integration, and custom
      business logic. Our solutions simplify the deployment process,
      reduce manual tasks, and streamline the application lifecycle`,
      keys: blueprintKeys,
      app: blueprintUKeys,
    },
    {
      title: "Develop Blueprints for Application Automation",
      desc: `We create blueprints to automate application deployment,
      following industry best practices and integrating with your
      organization's environment, processes, and tools. Our solutions
      simplify application deployment, reduce manual tasks, and
      streamline the application lifecycle`,
      keys: blueAppKeys,
      app: blueAppUKeys,
    },
    {
      title: "Integrations with Third-Party Solutions",
      desc: `We provide seamless integration with third-party solutions, such as ITSM, security scans, reporting, and IPAM. Our
      integrations improve collaboration, streamline processes, and
      enhance the overall efficiency of your IT environment`,
      keys: thirdPartyKeys,
      app: thirdPartyUKeys,
    },
    {
      title: "Develop Custom Solutions based on Customer Requirements",
      desc: `We develop custom IT solutions tailored to your organization's unique requirements and goals. Our team works closely with you to understand your needs and create solutions that drive efficiency, growth, and innovation`,
      keys: customKeys,
      app: customUKeys,
    },
  ];

  const designConsiderations = [
    {
      title: "Workload assessment",
      designDesc:
        "Analyze the existing workloads and requirements to determine the best deployment strategy for each application or service",
    },
    {
      title: "Data sovereignty and compliance",
      designDesc:
        "Ensure your hybrid cloud solution complies with data protection regulations and industry-specific requirements",
    },
    {
      title: "Security and privacy",
      designDesc:
        "Implement robust security measures, such as encryption, access controls, and network segmentation, to safeguard sensitive data",
    },
    {
      title: "Network connectivity",
      designDesc:
        "Design a robust network architecture that provides low latency, high bandwidth, and secure connectivity between the private and public cloud environments",
    },

    {
      title: "Interoperability and integration",
      designDesc:
        "Ensure seamless integration and interoperability between different cloud platforms and services, as well as on-premises systems",
    },
    {
      title: "Cost optimization",
      designDesc:
        "Implement centralized management and monitoring tools to oversee the entire hybrid cloud environment and maintain visibility into resources and performance",
    },
    {
      title: "Disaster recovery and business continuity",
      designDesc:
        "Incorporate BCDR planning into your hybrid cloud solution to ensure the availability and recoverability of your critical applications and data",
    },
    {
      title: "Scalability and elasticity",
      designDesc:
        "Design the hybrid cloud solution to accommodate future growth, ensuring it can scale up or down as needed to meet changing demands",
    },
    {
      title: "Automation and orchestration",
      designDesc:
        "Incorporate automation and orchestration tools to simplify IT operations, streamline processes, and improve resource utilization",
    },
  ];

  const [quoteDetails, setQuoteDetails] = useState(initialDetails);
  const [validationErrors, setValdiationErrors] = useState(initialErrors);

  const [resMsg, setResMsg] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const setDetails = (value, key) => {
    setQuoteDetails({ ...quoteDetails, [key]: value });
    setValdiationErrors({ ...validationErrors, [key]: false });
  };

  const handleSendEmail = () => {
    const email = appConstants.mail; // Replace with the recipient's email address
    const subject = "Request for quote"; // Replace with the desired subject line
    const body = "Hi avancerPI solutions, Shall we connect!"; // Replace with the desired email body

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  const sendDetails = (e) => {
    e.preventDefault();
    const requestData = validateFields();
    if (requestData) {
      if (requestData) {
        setLoading(true);
        props.createRequestQuote(
          requestData,
          (res) => {
            const response = res;
            if (Number(response.statusCode) === 200) {
              setResMsg({
                icon: <CheckCircle style={{ color: appColors.whiteColor }} />,
                msg: response.message,
                bgColor: appColors.greenOverlay,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 3000);
              setValdiationErrors(initialErrors);
              setQuoteDetails(initialDetails);
              setLoading(false);
            } else {
              setResMsg({
                icon: (
                  <WarningAmberOutlined
                    style={{ color: appColors.whiteColor }}
                  />
                ),
                msg: "Oops! Something went wrong!",
                bgColor: appColors.red,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 2000);
              setLoading(false);
            }
          },
          false,
          false
        );
      }
    } else {
      setResMsg({
        icon: <WarningAmberOutlined style={{ color: appColors.whiteColor }} />,
        msg: "Check the details entered",
        bgColor: appColors.red,
      });
      setTimeout(() => {
        setResMsg(false);
      }, 2000);
      setLoading(false);
    }
  };

  const validateFields = () => {
    let formData = new FormData();
    let errors = { ...validationErrors };

    if (formValidators.checkEmpty(quoteDetails.name)) {
      errors.name = "Enter name";
      formData = undefined;
    } else {
      errors.name = false;
      formData?.append("name", quoteDetails.name);
    }

    if (formValidators.checkEmpty(quoteDetails.mobile)) {
      errors.mobile = "Enter mobile";
      formData = undefined;
    } else if (!formValidators.checkMobile(quoteDetails.mobile)) {
      errors.mobile = "Enter valid mobile";
      formData = undefined;
    } else {
      errors.mobile = false;
      formData?.append("mobile", quoteDetails.mobile);
    }
    if (formValidators.checkEmpty(quoteDetails.email)) {
      errors.email = "Enter email";
      formData = undefined;
    } else if (!formValidators.checkEmail(quoteDetails.email)) {
      errors.email = "Enter valid email";
      formData = undefined;
    } else {
      errors.email = false;
      formData?.append("email", quoteDetails.email);
    }
    if (formValidators.checkEmpty(quoteDetails.sub)) {
      errors.sub = "Enter subject";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.sub, 10)) {
      errors.sub = "Subject should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.sub = false;
      formData?.append("subject", quoteDetails.sub);
    }
    if (formValidators.checkEmpty(quoteDetails.message)) {
      errors.message = "Enter message";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.message, 10)) {
      errors.message = "Message should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.message = false;
      formData?.append("message", quoteDetails.message);
    }
    setValdiationErrors(errors);
    return formData;
  };

  return (
    <div className="mainContentSection">
      <Toast
        showToast={Boolean(resMsg)}
        data={resMsg}
        closeToast={() => setResMsg(undefined)}
      />
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"Cloud Automation"}</h1>

          <p className="subNavAbout mt10">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; Services
          </p>
        </div>
      </div>
      <div className="centerGrid80 aboutWhoWeAreCont mt20">
        <div className="hciContHeadCont">
          <p className="whoWeHeadTxt">
            Hybrid Cloud and <br />
            Cloud Automation Solutions
          </p>

          <div className="whoWeUnderline" />
        </div>
        <div className="whoWeContCont">
          <p className="whoWeContTxt">
            Our hybrid cloud and cloud automation solutions enable organizations
            to optimize their IT infrastructure by integrating the best features
            of private and public cloud environments. We help organizations
            design, implement, and manage hybrid cloud solutions that offer
            flexibility, cost efficiency, and seamless operation. Additionally,
            we provide automation services that simplify IT operations, improve
            resource utilization, and accelerate service delivery.
            <br />
          </p>
        </div>
      </div>
      <div className="serviceVdoCont desConOutCont mt20">
        <div className="centerGrid90 desConCont">
          <div className="servVdoLHS bgTrans">
            <p className="smallBoldTitles textGrey">Under Cloud Automation</p>

            <p className="whoWeHeadTxt textPrim">Main key features</p>
            <p className="whoWeHeadTxt textPrim">and benefits</p>
            <br />
            <img
              src={appImages.services.hciTestBanner}
              style={{
                width: "70%",
                height: "40%",
              }}
              alt="avancerImg"
            />
          </div>

          <div className="homeTestiContCont">
            {accordianData.map((accord, i) => {
              return (
                <Fragment>
                  <div
                    className="aboutUsAccorCont"
                    onClick={() => {
                      setAccordIndex(i);
                    }}
                  >
                    <p className="accordHeadText">{accord?.title}</p>
                    {accordIndex !== i ? (
                      <ArrowDropDown className="aboutAccArrow" />
                    ) : (
                      <ArrowDropUp className="aboutAccArrow" />
                    )}
                  </div>
                  {accordIndex === i && (
                    <div className="accordContCont">
                      <p>{accord?.desc}</p>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className="homeAboutCont centerGrid80">
        <div className="homeAboutImgCont">
          <img
            className="homeAboutImg"
            alt="avancerAboutImg"
            src={appImages.homeImages.Aboutus}
          />
        </div>
        <div className="homeAboutContentsCont mt20">
          <div className="homeAboutLine"></div>
          <p className="homeAboutTxt aboutHeadingHome">
            Unique Approach
            <br />
            and Expertise
          </p>
          <div className="aboutDetailsHomeCont">
            <span>
              <ul className="homeAboutList">
                {effPoints.map((content, i) => {
                  return (
                    <li className="aboutInnList" key={i}>
                      <CheckCircle className="aboutListIcon" />
                      <p className="aboutInnContent">
                        <span style={{ fontWeight: "700" }}>
                          {content?.title}
                        </span>{" "}
                        - {content?.desc}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </span>
            <p className="aboutHomeContent" style={{ fontSize: 18 }}>
              By taking into account all relevant considerations, including
              industry best practices, global standards, white papers, and the
              unique needs of your organization, we deliver exceptional workload
              migration services that optimize your infrastructure and drive
              business growth.
            </p>
          </div>
        </div>
      </div>

      <p
        className="aboutHeadingHome textAlignCenter"
        style={{
          marginTop: 30,
          marginBottom: 30,
          color: appColors.secondaryColor,
        }}
      >
        Design considerations
        <br />
        on HCI
      </p>

      <div className="considCardCont">
        <div className="considCardBox">
          {[...designConsiderations].map((card, i) => {
            return (
              <div className="considCardItem" key={i}>
                <span className="considCardTitle">
                  <div className="considCardBg"></div>

                  <img
                    className="iconArrowCard"
                    src={appImages.appImages.appArrowLogo}
                    alt="avancerImg"
                  />
                  <div className="considCardItemTitle">{card?.title}</div>
                  <p className="considCardDesc">{card?.designDesc}</p>
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <p
        className="aboutHeadingHome textAlignCenter"
        style={{
          marginTop: 20,
          marginBottom: 20,
          color: appColors.primaryColor,
        }}
      >
        Subservices we offer
        <br />
        under Cloud Automation
      </p>
      <div className="cloudServCardCont">
        <div className="cloudServCont">
          {subServices.map((subServ, i) => {
            return (
              <div key={i} className="keyCloudCont">
                <div className="keyFeatCloudCont">
                  <div className="mainDescTitleCloudBg">
                    <h4 className="cloudTeamTitle">
                      Key Features and Benefits :
                    </h4>

                    <ul className="teamsListCont">
                      {subServ.keys.map((subKey, i) => {
                        return (
                          <li className="teamsInnList" key={i}>
                            <p className="cloudTeamDesc">
                              <b className="cloudKeyTitle">{subKey?.title}</b>
                              {subKey?.content}
                            </p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <div className="mainLogoCloudCont">
                  <div className="mainLogoInnCont">
                    <div className="custLogoAvatar">
                      <h4 className="cloudServTitle">
                        <p className="cloudOpenQuotes">0 {i + 1} .</p>
                        {subServ?.title}
                      </h4>
                      <p className="clientFeedbackTitle">{subServ?.desc}</p>
                      <p className="viewMoreCloud">View more</p>
                    </div>
                  </div>
                </div>
                <div className="mainContentsCloudCont">
                  <div className="mainContentsInnCont">
                    <div className="clientFeedbackCont">
                      <div className="teamsListRHSCont">
                        <h4 className="cloudDetTitle">
                          Unique Approach and Expertise :
                        </h4>
                        <ul className="teamsListRHSList">
                          {subServ.app.map((approach, i) => {
                            return (
                              <li className="teamsInnList" key={i}>
                                <p className="cloudDetDesc">
                                  <b className="cloudKeyTitleR">
                                    {approach.title}
                                  </b>
                                  {approach.content}
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className="centerGrid60 servFormCont mt20"
        style={{ justifyContent: "space-between" }}
      >
        <div className="servVdoLHS servFormLHS">
          <form>
            <input
              className="newLetterInput"
              placeholder="Name *"
              value={quoteDetails.name}
              onChange={(e) => {
                setDetails(e.target.value, "name");
              }}
            />
            {formValidators.formErrors(validationErrors.name)}

            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Email address *"
              value={quoteDetails.email}
              onChange={(e) => {
                setDetails(e.target.value, "email");
              }}
            />

            {formValidators.formErrors(validationErrors.email)}
            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Mobile *"
              value={quoteDetails.mobile}
              onChange={(e) => {
                setDetails(e.target.value, "mobile");
              }}
            />
            {formValidators.formErrors(validationErrors.mobile)}

            <input
              className="newLetterInput"
              style={{ marginTop: 25 }}
              placeholder="Subject *"
              value={quoteDetails.sub}
              onChange={(e) => {
                setDetails(e.target.value, "sub");
              }}
            />
            {formValidators.formErrors(validationErrors.sub)}

            <textarea
              className="newLetterInput"
              style={{ marginTop: 25, minHeight: 100 }}
              placeholder="Your message *"
              value={quoteDetails.message}
              onChange={(e) => {
                setDetails(e.target.value, "message");
              }}
            />
            {formValidators.formErrors(validationErrors.message)}

            <button
              disabled={Boolean(loading)}
              className="formSubmitBtn"
              onClick={(e) => sendDetails(e)}
              style={{ cursor: Boolean(loading) ? "not-allowed" : "pointer" }}
            >
              {loading ? "Submitting..." : "SEND MESSAGE"}
            </button>
          </form>
        </div>
        <div className="servVdoRHS letsWorkLHS">
          <p className="whoWeContHeadTxt">
            Let's work together on your next Cloud Automation Platform!
          </p>
          <p
            className="whoWeContHeadTxt"
            style={{ fontWeight: "normal", fontSize: 12 }}
          >
            Call us for immediate support to this number
          </p>
          <p className="whoWeContHeadTxt cp" onClick={handlePhoneNumberClick}>
            {appConstants.phoneNumber}
          </p>
          <div className="socialIcContServOut">
            <div
              className="socialIcContServ"
              onClick={() => {
                handleSendEmail();
              }}
            >
              <LocalPostOffice fontSize="small" className="socialIconServ" />
            </div>
            <div
              className="socialIcContServ"
              onClick={() => {
                openWhatsApp();
              }}
            >
              <WhatsApp fontSize="small" className="socialIconServ" />
            </div>
            <div
              className="socialIcContServ"
              onClick={() => {
                window.open(appConstants.linkedIn, "_blank");
              }}
            >
              <LinkedIn fontSize="small" className="socialIconServ" />
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div
        className="centerGrid60 servFormCont"
        style={{ justifyContent: "space-between" }}
      >
        <div className="servVdoLHS servFormLHS">
          <form>
            <input
              className="newLetterInput"
              placeholder="Name *"
              value={quoteDetails.name}
              onChange={(e) => {
                setDetails(e.target.value, "name");
              }}
            />
            {formValidators.formErrors(validationErrors.name)}

            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Email address *"
              value={quoteDetails.email}
              onChange={(e) => {
                setDetails(e.target.value, "email");
              }}
            />

            {formValidators.formErrors(validationErrors.email)}
            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Mobile *"
              value={quoteDetails.mobile}
              onChange={(e) => {
                setDetails(e.target.value, "mobile");
              }}
            />
            {formValidators.formErrors(validationErrors.mobile)}

            <input
              className="newLetterInput"
              style={{ marginTop: 25 }}
              placeholder="Subject *"
              value={quoteDetails.sub}
              onChange={(e) => {
                setDetails(e.target.value, "sub");
              }}
            />
            {formValidators.formErrors(validationErrors.sub)}

            <textarea
              className="newLetterInput"
              style={{ marginTop: 25, minHeight: 100 }}
              placeholder="Your message *"
              value={quoteDetails.message}
              onChange={(e) => {
                setDetails(e.target.value, "message");
              }}
            />
            {formValidators.formErrors(validationErrors.message)}

            <button
              disabled={Boolean(loading)}
              className="formSubmitBtn"
              onClick={(e) => sendDetails(e)}
              style={{ cursor: Boolean(loading) ? "not-allowed" : "pointer" }}
            >
              {loading ? "Submitting..." : "SEND MESSAGE"}
            </button>
          </form>
        </div>
        <div className="servVdoRHS letsWorkLHS">
          <p className="whoWeContHeadTxt">
            Let's work together on your next HCI Platform!
          </p>
          <p
            className="whoWeContHeadTxt"
            style={{ fontWeight: "normal", fontSize: 12 }}
          >
            Call us for immediate support to this number
          </p>
          <p className="whoWeContHeadTxt">+91 9902693693</p>
          <div className="socialIcContServOut">
            <div className="socialIcContServ ">
              <Instagram fontSize="small" className="socialIconServ" />
            </div>
            <div className="socialIcContServ">
              <Twitter fontSize="small" className="socialIconServ" />
            </div>
            <div className="socialIcContServ">
              <LinkedIn fontSize="small" className="socialIconServ" />
            </div>
            <div className="socialIcContServ">
              <Facebook fontSize="small" className="socialIconServ" />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRequestQuote: (requestData, onResponse) => {
      dispatch(createRequestQuote(requestData, onResponse));
    },
  };
};

export default connect(null, mapDispatchToProps)(CloudAuto);
