import React from "react";
import "../../styles/layoutSass/home/base.scss";
import appImages from "../../assets";

const NoScreen = () => {
  return (
    <div className="mainContentSection">
      <div className="mainSliderHomeOuterCont">
        <div
          className="mainSliderHomeCont"
          style={{
            height: 500,
            backgroundImage: `url(${appImages.appImages.noPage})`,
            marginTop: 25,
          }}
        >
          <div className="nodataContainer">
            <h1>Oops ! Page not found !</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoScreen;
