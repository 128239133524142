import React, { Fragment, useState } from "react";
import appImages from "../../assets";
import { Link, useLocation } from "react-router-dom";

import {
  LinkedIn,
  Cancel,
  MenuOpen,
  KeyboardArrowDown,
  KeyboardArrowUp,
  WhatsApp,
} from "@mui/icons-material";
import { useNavigate } from "react-router";
import appColors from "../../utils/themes/colorThemes";
import openWhatsApp from "../../utils/commonFunctions/openWhatsapp";
import store from "../../redux/store";
import { isMenuOpen } from "../../redux/actions/app.actions";

const HeaderMobile = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const myStore = store.getState();

  const [menuOpen, setMenuOpen] = useState(myStore?.auth?.isMenuOpen || false);

  const currentRoute = location.pathname;

  const mobileMenubar = window.matchMedia("(min-width: 600px)").matches
    ? "30px"
    : "25px";
  const [subServ, setsubServ] = useState(false);

  const handleMenuClose = () => {
    store.dispatch(isMenuOpen(false));
    setMenuOpen(false);
  };
  React.useEffect(() => {
    let isActive = true;
    return () => {
      let isActive = false;
    };
  }, [mobileMenubar]);

  return (
    <div className="mobileHeaderContainer" id="mobileHeader">
      <div className="mobLogoMenuCont" style={{ marginTop: "0px" }}>
        <div
          className="applogoMobile"
          onClick={() => {
            navigate("/home");
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            src={appImages.appImages.logo}
            className="mobLogo"
            alt="avancerPIAppLogo"
            style={{ marginLeft: 15 }}
          />
        </div>
        <button
          className="menuBarBtn"
          onClick={() => {
            store.dispatch(isMenuOpen(!isMenuOpen));
            setMenuOpen(!menuOpen);
          }}
        >
          {!menuOpen ? (
            <MenuOpen
              className="headerMenuIcon"
              style={{ fontSize: mobileMenubar }}
            />
          ) : (
            <Cancel
              className="headerMenuIconBlue"
              style={{ fontSize: mobileMenubar }}
            />
          )}
        </button>
      </div>
      {menuOpen && (
        <div className="menuBarMobile">
          <ul className="menuBarList">
            <li>
              <Link
                onClick={handleMenuClose}
                to={"/home"}
                className={`menuBarItem  ${
                  currentRoute === "/home" ? "activeMenuColor" : ""
                }`}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                onClick={handleMenuClose}
                to={"/About"}
                className={`menuBarItem  ${
                  currentRoute === "/About" ? "activeMenuColor" : ""
                }`}
              >
                About
              </Link>
            </li>
            <li>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  setsubServ(!subServ);
                }}
                // to={"/Services"}
                className={`menuBarItem  ${
                  currentRoute === "/HCI" ||
                  currentRoute === "/Services" ||
                  currentRoute === "/DisasterRecovery" ||
                  currentRoute === "/CloudAutomation" ||
                  currentRoute === "/AppDevelopment" ||
                  currentRoute === "/AppModernization" ||
                  currentRoute === "/WorkLoadMigration"
                    ? "activeMenuColor"
                    : ""
                }`}
                // className="menuBarItem"
              >
                Services
                <span>
                  {!subServ ? (
                    <KeyboardArrowDown
                      style={{
                        marginLeft: 5,
                        fontSize: 20,
                        color: appColors.primaryColor,
                      }}
                    />
                  ) : (
                    <KeyboardArrowUp
                      style={{
                        marginLeft: 5,
                        fontSize: 20,
                        color: appColors.primaryColor,
                      }}
                    />
                  )}
                </span>
              </div>
            </li>
            {subServ && (
              <Fragment>
                <li>
                  <Link
                    onClick={handleMenuClose}
                    to={"/HCI"}
                    className={`menuBarItem subServ ${
                      currentRoute === "/HCI" ? "activeMenuColor" : ""
                    }`}
                  >
                    HCI
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleMenuClose}
                    to={"/WorkLoadMigration"}
                    className={`menuBarItem subServ ${
                      currentRoute === "/WorkLoadMigration"
                        ? "activeMenuColor"
                        : ""
                    }`}
                  >
                    WorkLoad Migration
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleMenuClose}
                    to={"/DisasterRecovery"}
                    className={`menuBarItem subServ ${
                      currentRoute === "/DisasterRecovery"
                        ? "activeMenuColor"
                        : ""
                    }`}
                  >
                    Disaster Recovery
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleMenuClose}
                    to={"/CloudAutomation"}
                    className={`menuBarItem subServ ${
                      currentRoute === "/CloudAutomation"
                        ? "activeMenuColor"
                        : ""
                    }`}
                  >
                    Cloud Automation
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={handleMenuClose}
                    to={"/AppDevelopment"}
                    className={`menuBarItem subServ ${
                      currentRoute === "/AppDevelopment"
                        ? "activeMenuColor"
                        : ""
                    }`}
                  >
                    Web Development
                  </Link>
                </li>
              </Fragment>
            )}
            <li>
              <Link
                onClick={handleMenuClose}
                to={"/Career"}
                className={`menuBarItem  ${
                  currentRoute === "/Career" ? "activeMenuColor" : ""
                }`}
              >
                Career
              </Link>
            </li>
            <li>
              <Link
                onClick={handleMenuClose}
                to={"/Contact"}
                className={`menuBarItem  ${
                  currentRoute === "/ContactUs" ? "activeMenuColor" : ""
                }`}
              >
                Contact
              </Link>
            </li>
          </ul>
          <ul className="socialIconsMenuBtn">
            <LinkedIn
              className="socialIconsMenuBar cp"
              onClick={() => {
                window.open(
                  "https://in.linkedin.com/company/avancerpi",
                  "_blank"
                );
              }}
              style={{
                fontSize: `calc(${mobileMenubar} - 5px )`,
              }}
            />
            <WhatsApp
              className="socialIconsMenuBar"
              style={{ color: "green" }}
              onClick={() => {
                openWhatsApp();
              }}
            />
          </ul>
        </div>
      )}
    </div>
  );
};

export default HeaderMobile;
