import { Cancel, Remove } from "@mui/icons-material";
import React from "react";
import "../styles/componentSass/base.scss";
import appColors from "../utils/themes/colorThemes";

const Toast = ({
  showToast,
  closeToast,
  toastColor,
  data,
  toasterContainerStyle,
  toasterStyle,
  toastIconContStyle,
  toastDescStyle,
}) => {
  return showToast ? (
    <div id="toastCont" style={toasterContainerStyle || {}}>
      <div
        id="toast"
        style={{
          ...(toasterStyle || {}),
          background: toastColor || data?.bgColor || appColors.grey,
        }}
      >
        {data?.icon && (
          <div id="toastIcon" style={toastIconContStyle || {}}>
            {data?.icon}
          </div>
        )}
        <div id="toastDesc" style={toastDescStyle || {}}>
          {data?.msg || ""}
        </div>
        <Cancel
          onClick={closeToast}
          className="toasterClose"
          fontSize="small"
        />
      </div>
    </div>
  ) : null;
};

export default Toast;
