const Endpoints = {
  GET_QUOTE_LIST: "requestQuote/getQuotationList",
  CREATE_REQUEST_QUOTE: "requestQuote/createRequestQuote",
  UPDATE_STATUS_REQUEST_QUOTE: "requestQuote/updateQuoteStatus",
  REMOVE_REQUEST_QUOTE: "requestQuote/removeQuote",

  SERVICES_LIST: "services/getServiceList",
  CREATE_SERVICE: "services/createService",
  UPDATE_SERVICE: "services/updateService",
  REMOVE_SERVICE: "services/removeService",

  ADMIN_LOGIN: "admin/adminLogin",
  FORGOT_PASSWORD_CHECK: "admin/forgotPasswordCheck",
  FORGOT_PASSWORD: "admin/forgotPassword",

  GET_EVENTS: "events/getEventList",
  JOIN_EVENT: "events/joinEvent",
  CREATE_EVENT: "events/createEvent",
  UPLOAD_EVENT: "events/updateEvent",
  REMOVE_EVENT: "events/removeEvent",
  GET_PARTICIPANTS_LIST: "events/getEventParticipantsList",

  RECRUITMENT_LIST: "recruitment/getRecruitmentList",
  CREATE_RECRUITMENT: "recruitment/createRecruitment",
  UPDATE_RECRUITMENT: "recruitment/updateRecruitment",
  REMOVE_RECRUITMENT: "recruitment/removeRecruitment",
  APPLY_RECRUITMENT: "recruitment/applyRecruitment",
  APPLICANTS_RECRUITMENT_LIST: "recruitment/getApplicantsList",

  CREATE_EMPLOYEE: "employee/createEmployee",
  GET_EMPLOYEES_LIST: "employee/getEmployeeList",
  UPDATE_EMPLOYEE: "employee/updateEmployee",
  REMOVE_EMPLOYEE: "employee/removeEmployee",
};

export default Endpoints;
