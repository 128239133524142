export const appConstants = {
  emailRegEx: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  mobileRegEx: /^[0-9]{10}/,
  phoneNumber: "+91 9902693693",

  // Construct the WhatsApp Web URL
  whatsApp: "https://web.whatsapp.com/send?phone=+91 9902693693",
  linkedIn: "https://in.linkedin.com/company/avancerpi",
  mail: "akmal@avancerpi.com",
  mapAddress:
    "https://www.google.com/maps/place/Splendid+Plaza/@12.9963757,77.6150868,17z/data=!3m1!4b1!4m6!3m5!1s0x3bae1781ea440a57:0x412ab6ef2c5bb9a3!8m2!3d12.9963705!4d77.6176617!16s%2Fg%2F11thfrcpfj?entry=ttu",
};

export default appConstants;
