import React from "react";
import { connect } from "react-redux";
// import "../../../styles/layoutSass/services/datacenter/base.scss";
import appImages from "../../../assets";
import { useNavigate } from "react-router-dom";
import appColors from "../../../utils/themes/colorThemes";
import { ChevronRight } from "@mui/icons-material";
import Slider from "../LogoSlider";

export const Services = (props) => {
  const navigate = useNavigate();

  const services = [
    {
      name: "HCI",
      navigationRoute: "/HCI",
      serviceDesc:
        "Experience streamlined IT infrastructure with reduced complexity and enhanced performance. Our team designs and implements highly available, scalable, and flexible solutions tailored to your specific needs",

      serviceIcon: appImages.icons.hci,
    },
    {
      name: "Workload Migration",
      navigationRoute: "/WorkLoadMigration",
      serviceDesc:
        "Seamlessly transition your critical business applications and data to new platforms with our expert assistance, ensuring minimal downtime and uninterrupted operations",

      serviceIcon: appImages.icons.workMig,
    },

    {
      name: "Disaster Recovery",
      navigationRoute: "/DisasterRecovery",
      serviceDesc:
        "Equip your organization with a comprehensive plan and solution, as we implement a robust disaster recovery strategy customized to your specific requirements",

      serviceIcon: appImages.icons.disRec,
    },
    {
      name: "Cloud Automation",
      navigationRoute: "/CloudAutomation",
      serviceDesc:
        "Revolutionize your IT operations with automation, freeing up valuable time and resources to focus on strategic initiatives that drive business growth",

      serviceIcon: appImages.icons.cloudAuto,
    },

    {
      name: "App development",
      navigationRoute: "/AppDevelopment",
      serviceDesc:
        "Capture your audience's attention with engaging, user-friendly websites that deliver an unparalleled user experience, crafted by our talented web development team",

      serviceIcon: appImages.icons.webDev,
    },
    {
      name: "App Modernization",
      navigationRoute: "/AppModernization",
      serviceDesc:
        "Stay ahead of the competition by updating and modernizing legacy applications, ensuring your organization remains agile and responsive in the digital landscape",

      serviceIcon: appImages.icons.appModern,
    },
  ];

  //navigate to subservice
  const navigateSubService = (service, subService) => {
    navigate(service.navigationRoute);
  };

  return (
    <div className="mainContentSection">
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading ">{"Services"}</h1>

          <p className="subNavAbout mt10">
            <span
              className="cp"
              onClick={() => {
                navigate("Home");
              }}
            >
              Home &nbsp;
            </span>
            <ChevronRight fontSize="large" className="subNavArrow" />
            <span
              className="cp"
              onClick={() => {
                navigate("About");
              }}
            >
              &nbsp; About
            </span>
          </p>
        </div>
      </div>
      <div className="centerGrid80 aboutWhoWeAreCont mt20">
        <div className="whoWeHeadCont">
          <p className="whoWeHeadTxt">Merge </p>
          <p className="whoWeHeadTxt" style={{ color: appColors.primaryColor }}>
            Technical expertise with strategic insight
          </p>
          <p className="whoWeHeadTxt">to Deliver Unparalleled Results</p>
          <div className="whoWeUnderline" />
        </div>
        <div className="whoWeContCont">
          {/* <p className="whoWeContHeadTxt">
            Pioneering Future-Ready Solutions for Seamless Integrations
          </p> */}
          <p className="whoWeContTxt">
            Our team has helped clients transform their IT operations through
            consulting workshops, becoming a trusted advisor in their journey to
            achieve cloud and agility. In the design phase, we work closely with
            stakeholders and subject matter experts to understand project
            objectives and requirements. Our documentation includes a
            comprehensive analysis of the current state, conceptual, logical,
            and physical design, requirements, constraints, assumptions, risks,
            and design decisions. Our focus is always on achieving high
            availability, security, manageability, scalability, and simplicity.
            <br />
          </p>
        </div>
      </div>
      <p className="headTxtSmall smallBoldTitles mt20">
        <b>OUR SERVICES OFFERINGS</b>
      </p>
      <div className="centerGrid60 servicesMainPageCont mt20">
        {services.map((serv, i) => {
          return (
            <div key={i} className="serviceCardSingle">
              <div className="cardContLayer1">
                <img
                  src={serv.serviceIcon}
                  className="servImgInCard"
                  alt="serviceImg"
                />
                <h1 className="servName">
                  {serv.name}
                  {/* {serv.name.split(" ")[0]}
                  <br />
                  {serv.name.split(" ")[1] && serv.name.split(" ")[1]} */}
                </h1>
              </div>
              <div className="curveCardIns">
                <p className="serviceDescCard">{serv.serviceDesc}</p>
                <div className="serviceNamewithDesc">
                  {/* <p className="learnMoreBtn">Learn More</p> */}
                  <button
                    onMouseOver={(event) => {
                      document.getElementsByClassName("learnMoreBtnServ")[
                        i
                      ].style.backgroundColor = "#0097ac";
                    }}
                    onMouseOut={(event) => {
                      document.getElementsByClassName("learnMoreBtnServ")[
                        i
                      ].style.backgroundColor = "#00506c";
                    }}
                    onClick={() => navigateSubService(serv)}
                    className="learnMoreBtnServ"
                    style={{
                      backgroundColor: "#00506c",
                      marginTop: 10,
                    }}
                  >
                    Learn More
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="clientSliderContHome mt20" style={{ marginBottom: -100 }}>
        <p className="headTxtSmall smallBoldTitles">
          <b>OUR TECHNOLOGY PARTNERS </b>
        </p>
        <p
          className="aboutHeadingHome textAlignCenter"
          style={{
            margin: "0 auto",
            marginTop: 10,
          }}
        >
          Committed to Helping
          <br />
          Our technology partners Succeed
        </p>

        <div className="customMarquee">
          <div className="marqueeInner">
            <Slider />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
