import appArrowLogo from "./appImages/appArrowLogo.png";
import appArrowLogoBlack from "./appImages/appArrowLogoBlack.png";

import logo from "./appImages/logoBlack.png";
import noPage from "./appImages/404.png";
import careerDescBg from "./appImages/careerDescBg.png";

//homeScreenImages
import testimonialsBg from "./home/testimonialsBg.jpg";
import Aboutus from "./home/Aboutus.jpg";
import homeTagSquare from "./home/homeTagSquare.png";

import vmware from "./logos/vmware.png";
import aws from "./logos/aws.png";
import nutanixshort from "./logos/nutanixshort.png";
import redhat from "./logos/redhat.png";

import consult from "./icons/consult.png";
import build from "./icons/build.png";
import design from "./icons/design.png";
import train from "./icons/train.png";
import disRec from "./icons/disRec.png";
import appModern from "./icons/appModern.png";
import cloudAuto from "./icons/cloudAuto.png";
import hci from "./icons/hci.png";
import webDev from "./icons/webDev.png";
import workMig from "./icons/workMig.png";
import plan from "./icons/plan.png";
import delivery from "./icons/delivery.png";
import exec from "./icons/exec.png";
import frnd from "./icons/frnd.png";

import aboutMainBanner from "./about/mainBanner.png";
import certificationBanner from "./about/certificationBanner.png";

import nca from "./certifications/nca.png";
import ncap from "./certifications/ncap.png";
import ncp from "./certifications/ncp.png";
import ncsc from "./certifications/ncsc.png";
import ncscca from "./certifications/ncscca.png";
import ncsccatrainer from "./certifications/ncsccatrainer.png";
import ncse from "./certifications/ncse.png";
import ncsr from "./certifications/ncsr.png";
import ncsrlevel2 from "./certifications/ncsrlevel2.png";

import hciTestBanner from "./services/hciTestBanner.png";

import success1 from "./services/success1.png";
import success2 from "./services/success2.png";
import success3 from "./services/success3.png";
import success4 from "./services/success4.png";

import logoLoader from './logos/logoLoader.gif'

const appImages = {
  appImages: {
    appArrowLogo,
    appArrowLogoBlack,
    logo,
    noPage,
  },
  homeImages: {
    testimonialsBg,
    Aboutus,
    homeTagSquare,
  },
  aboutUsImgs: {
    aboutMainBanner,
    certificationBanner,
  },
  logos: {
    nutanixshort,
    redhat,
    vmware,
    aws,
    logoLoader
  },
  services: { hciTestBanner },
  career: {
    careerDescBg,
  },
  certifications: {
    nca,
    ncap,
    ncp,
    ncsc,
    ncscca,
    ncsccatrainer,
    ncse,
    ncsr,
    ncsrlevel2,
  },
  icons: {
    consult,
    build,
    design,
    train,
    hci,
    disRec,
    workMig,
    webDev,
    cloudAuto,
    appModern,
    plan,
    delivery,
    exec,
    frnd,
  },
  successStories: {
    success1,
    success2,
    success3,
    success4,
  },
};

export default appImages;
