import React, { Fragment, useState } from "react";
import "../../../styles/layoutSass/home/base.scss";
import "../../../styles/layoutSass/about/base.scss";
import "../../../styles/layoutSass/services/base.scss";
import "../../../styles/layoutSass/home/login/_login.scss";
import "../../../styles/layoutSass/services/_hcitest.scss";

import appColors from "../../../utils/themes/colorThemes";
import appImages from "../../../assets";
import {
  ArrowDropDown,
  ArrowRightAlt,
  CheckCircle,
  ChevronRight,
  LinkedIn,
  LocalPostOffice,
  WarningAmberOutlined,
  WhatsApp,
} from "@mui/icons-material";
import formValidators from "../../../utils/commonFunctions/formValidators";
import Toast from "../../../components/Toast";
import { connect } from "react-redux";
import { createRequestQuote } from "../../../redux/actions/form.actions";
import { ArrowDropUp } from "@material-ui/icons";
import appConstants from "../../../utils/appStrings/appConstants";
import openWhatsApp, {
  handlePhoneNumberClick,
} from "../../../utils/commonFunctions/openWhatsapp";

// const slides = [
//   {
//     city: "Paris",
//     country: "France",
//     img: appImages.successStories.success1,
//     customer: "Nutanix",
//     quotes: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book`,
//   },
//   {
//     city: "Singapore",
//     img: appImages.successStories.success2,
//     customer: "VMware",
//     quotes: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book`,
//   },
//   {
//     city: "Prague",
//     country: "Czech Republic",
//     img: appImages.successStories.success3,
//     customer: "Redhat",
//     quotes: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book`,
//   },
//   // {
//   //   city: "Amsterdam",
//   //   country: "Netherlands",
//   //   img: appImages.successStories.success4,
//   // },
// ];

const initialDetails = {
  name: "",
  email: "",
  mobile: "",
  sub: "",
  message: "",
};
const initialErrors = {
  name: false,
  email: false,
  mobile: "",
  sub: false,
  message: false,
};

const HCITest = (props) => {
  const accordianData = [
    {
      heading: "Simplified IT management",
      accordCont: `Consolidate multiple IT components into a single, unified platform, streamlining administration and reducing complexity`,
    },
    {
      heading: "Scalability",
      accordCont: `Easily scale resources as your organization grows, adding capacity and performance without significant downtime`,
    },
    {
      heading: "Cost savings",
      accordCont: `Reduce capital and operational expenses by optimizing resource utilization and eliminating the need for specialized hardware`,
    },
    {
      heading: "Enhanced performance",
      accordCont: `Benefit from optimized performance and reduced latency, ensuring your applications run smoothly and efficiently`,
    },
    {
      heading: "Improved data protection",
      accordCont: `Built-in features such as data deduplication, compression, and snapshot capabilities enhance data protection and recovery`,
    },
  ];
  const [accordIndex, setAccordIndex] = useState(0);

  const rackKeys = [
    {
      title: "Seamless deployment : ",
      content:
        "Our team handles the entire process of racking and stacking servers, ensuring a hassle-free and efficient setup that follows design best practices",
    },
    {
      title: "Cluster deployment and configuration : ",
      content:
        "We design and implement customized cluster configurations to meet your unique requirements while adhering to design principles that ensure high availability, security, and scalability",
    },
    {
      title: "Testing and validation : ",
      content:
        "Rigorous testing and validation processes are conducted to ensure the stability and performance of your infrastructure, adhering to our design principles",
    },
    {
      title: "Comprehensive documentation : ",
      content:
        "Receive detailed as-built and operations guide documentation, simplifying ongoing management and maintenance, and highlighting our commitment to design best practices",
    },
  ];

  const healthKeys = [
    {
      title: "Comprehensive analysis",
      content:
        "We perform a thorough examination of your infrastructure, identifying potential issues and areas for improvement while considering our design principles",
    },
    {
      title: "Detailed health check report",
      content:
        "Receive an extensive report outlining identified issues, their causes, and recommended resolutions based on our design principles",
    },
    {
      title: "Expert guidance",
      content:
        "Our team of experts will provide guidance on implementing the suggested improvements to enhance your infrastructure's performance and stability, all while adhering to our design principles",
    },
  ];
  const remedKeys = [
    {
      title: "Customized remediation plan",
      content:
        "Based on the findings from the health check report, we develop a tailored plan to address identified issues and optimize your infrastructure while adhering to our design principles",
    },
    {
      title: "Expert implementation",
      content:
        "Our team of experts will implement the remediation plan, ensuring a smooth and efficient process that follows our design principles",
    },
    {
      title: "Enhanced performance and stability",
      content:
        "Experience improved performance, stability, and reliability as a result of our remediation and re-architecture services that adhere to our design principles",
    },
  ];
  const infraKeys = [
    {
      title: "Customized automation solutions",
      content:
        "Our team designs and implements automation solutions tailored to your unique infrastructure and requirements while adhering to our design principles",
    },
    {
      title: "Improved efficiency",
      content:
        "Streamline your IT operations and reduce manual tasks, resulting in increased efficiency and resource utilization, all while following our design principles",
    },
    {
      title: "Enhanced reliability",
      content:
        "Automation reduces the risk of human error and enhances the overall reliability of your infrastructure, adhering to our design principles",
    },
  ];

  const manageInfraKeys = [
    {
      title: "Expert support:",
      content:
        "Our team of professionals provides ongoing support, ensuring your infrastructure remains stable and efficient while adhering to our design principles",
    },
    {
      title: "Proactive monitoring",
      content:
        "We monitor your infrastructure for potential issues, addressing them before they escalate and impact performance, all while following our design principles",
    },
    {
      title: "Regular maintenance",
      content:
        "Our team performs regular maintenance tasks, keeping your infrastructure up-to-date and optimized for peak performance, and adhering to our design principles",
    },
  ];
  const upgradeKeys = [
    {
      title: "Customized upgrade plan:",
      content:
        "We develop a tailored plan for upgrading your infrastructure, ensuring minimal disruption and adherence to our design principles",
    },
    {
      title: "Expert implementation",
      content:
        "Our team of experts will oversee the upgrade process, ensuring a smooth and efficient transition while following our design principles",
    },
    {
      title: "Enhanced performance and stability",
      content:
        "Experience improved performance, stability, and reliability as a result of our upgrades and replatforming services that adhere to our design principles",
    },
  ];
  const subServices = [
    {
      title: "Rack and Stack servers",
      content:
        "Our team of experts provides end-to-end services for racking and stacking servers, deploying and configuring clusters, performing tests and validations, and delivering comprehensive documentation. By adhering to design principles that prioritize high availability, security, scalability, simple manageability, performance, and recoverability, we ensure a smooth deployment process and help you optimize your infrastructure for peak performance",
      keyFeat: rackKeys,
    },
    {
      title: `Health check`,
      content:
        "Our health check service offers an in-depth analysis of your existing deployed infrastructure, identifying potential issues and providing actionable recommendations for improvement. We help you optimize performance, enhance stability, and mitigate risks, all while adhering to the design principles that prioritize high availability, security, scalability, simple manageability, performance, and recoverability",
      keyFeat: healthKeys,
    },

    {
      title: "Remediation and re-architecture",
      content:
        "Leveraging insights from our health check service, we offer remediation and re-architecture services to address identified issues and optimize your infrastructure. Our team will work closely with you to develop and implement a tailored plan for improvement, following our design principles to ensure high availability, security, scalability, simple manageability, performance, and recoverability",
      keyFeat: remedKeys,
    },
    {
      title: "Infrastructure automation",
      content:
        "Our infrastructure automation services help organizations streamline their IT operations, improving efficiency and freeing up valuable resources for strategic initiatives. We provide customized automation solutions tailored to your specific needs and requirements, all while following our design principles to ensure high availability, security, scalability, simple manageability, performance, and recoverability ",
      keyFeat: infraKeys,
    },
    {
      title: "Resident services",
      content:
        "We offer managed infrastructure and resident services to help you maintain and optimize your IT environment. Our team of experts provides ongoing support, monitoring, and maintenance, ensuring your infrastructure remains up-to-date and performs optimally. We follow our design principles to ensure high availability, security, scalability, simple manageability, performance, and recoverability",
      keyFeat: manageInfraKeys,
    },
    {
      title: "Upgrades and replatforming",
      content:
        "Our upgrades and replatforming services help you stay ahead of the curve by updating and modernizing your infrastructure. We work closely with you to develop a tailored upgrade plan and ensure a seamless transition to the latest technologies. By adhering to our design principles, we prioritize high availability, security, scalability, simple manageability, performance, and recoverability throughout the upgrade process",
      keyFeat: upgradeKeys,
    },
  ];
  const designConsiderations = [
    {
      title: "Scalability",
      designDesc:
        "Design the HCI solution to accommodate future growth, ensuring it can easily scale out by adding nodes or scale up by increasing resources within nodes",
    },
    {
      title: "High Availability",
      designDesc:
        "Ensure redundancy and fault tolerance at every level, including hardware, networking, and storage, to maintain continuous operations in the event of component failures",
    },
    {
      title: "Performance",
      designDesc:
        "Optimize the solution to deliver the required performance for workloads, considering factors like CPU, memory, storage, and network resources",
    },
    {
      title: "Storage Efficiency",
      designDesc:
        "Implement data deduplication, compression, and erasure coding to maximize storage utilization and reduce costs",
    },

    {
      title: "Networking",
      designDesc:
        "Design the network architecture to provide sufficient bandwidth, low latency, and reliability while supporting the communication requirements of the HCI solution",
    },
    {
      title: "Data Protection",
      designDesc:
        "Incorporate backup, snapshot, and replication technologies to safeguard data and ensure rapid recovery in case of data loss or corruption",
    },
    {
      title: "Security",
      designDesc:
        "Implement security measures at every layer, including physical security, network segmentation, encryption, access controls, and monitoring",
    },
    {
      title: "Management Simplicity",
      designDesc:
        "Choose a management platform that simplifies day-to-day operations, automates routine tasks, and provides centralized monitoring and reporting",
    },
    {
      title: "Interoperability",
      designDesc:
        "Ensure that the HCI solution can integrate with existing systems and support third-party applications and services",
    },
    {
      title: "Total Cost of Ownership",
      designDesc:
        "Evaluate the overall TCO, including acquisition costs, ongoing maintenance, and operational expenses, to ensure the HCI solution is cost-effective",
    },
  ];
  // const subServicePoints = [
  //   "Rack & Stack servers and deploy & configure cluster. Perform test & validation and provide documentation related to as-build, operations guide",
  //   "Perform health check of existing deployed infrastructure and provide extensive health check report with issue identified, cause and resolutions",
  //   "Remediate and re-architect existing solutions based on health checks",
  //   "Workload Migrations",
  //   "Provide Infrastructure automations",
  //   "Manage existing infrastructure and provide resident services",
  //   "Upgrades and replatforms",
  // ];

  const [quoteDetails, setQuoteDetails] = useState(initialDetails);
  const [validationErrors, setValdiationErrors] = useState(initialErrors);

  const [resMsg, setResMsg] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const setDetails = (value, key) => {
    setQuoteDetails({ ...quoteDetails, [key]: value });
    setValdiationErrors({ ...validationErrors, [key]: false });
  };

  const handleSendEmail = () => {
    const email = appConstants.mail; // Replace with the recipient's email address
    const subject = "Request for quote"; // Replace with the desired subject line
    const body = "Hi avancerPI solutions, Shall we connect!"; // Replace with the desired email body

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  const sendDetails = (e) => {
    e.preventDefault();
    const requestData = validateFields();
    if (requestData) {
      if (requestData) {
        setLoading(true);
        props.createRequestQuote(
          requestData,
          (res) => {
            const response = res;
            if (Number(response.statusCode) === 200) {
              setResMsg({
                icon: <CheckCircle style={{ color: appColors.whiteColor }} />,
                msg: response.message,
                bgColor: appColors.greenOverlay,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 3000);
              setValdiationErrors(initialErrors);
              setQuoteDetails(initialDetails);
              setLoading(false);
            } else {
              setResMsg({
                icon: (
                  <WarningAmberOutlined
                    style={{ color: appColors.whiteColor }}
                  />
                ),
                msg: "Oops! Something went wrong!",
                bgColor: appColors.red,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 2000);
              setLoading(false);
            }
          },
          false,
          false
        );
      }
    } else {
      setResMsg({
        icon: <WarningAmberOutlined style={{ color: appColors.whiteColor }} />,
        msg: "Check the details entered",
        bgColor: appColors.red,
      });
      setTimeout(() => {
        setResMsg(false);
      }, 2000);
      setLoading(false);
    }
  };

  const validateFields = () => {
    let formData = new FormData();
    let errors = { ...validationErrors };

    if (formValidators.checkEmpty(quoteDetails.name)) {
      errors.name = "Enter name";
      formData = undefined;
    } else {
      errors.name = false;
      formData?.append("name", quoteDetails.name);
    }

    if (formValidators.checkEmpty(quoteDetails.mobile)) {
      errors.mobile = "Enter mobile";
      formData = undefined;
    } else if (!formValidators.checkMobile(quoteDetails.mobile)) {
      errors.mobile = "Enter valid mobile";
      formData = undefined;
    } else {
      errors.mobile = false;
      formData?.append("mobile", quoteDetails.mobile);
    }
    if (formValidators.checkEmpty(quoteDetails.email)) {
      errors.email = "Enter email";
      formData = undefined;
    } else if (!formValidators.checkEmail(quoteDetails.email)) {
      errors.email = "Enter valid email";
      formData = undefined;
    } else {
      errors.email = false;
      formData?.append("email", quoteDetails.email);
    }
    if (formValidators.checkEmpty(quoteDetails.sub)) {
      errors.sub = "Enter subject";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.sub, 10)) {
      errors.sub = "Subject should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.sub = false;
      formData?.append("subject", quoteDetails.sub);
    }
    if (formValidators.checkEmpty(quoteDetails.message)) {
      errors.message = "Enter message";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.message, 10)) {
      errors.message = "Message should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.message = false;
      formData?.append("message", quoteDetails.message);
    }
    setValdiationErrors(errors);
    return formData;
  };

  return (
    <div className="mainContentSection">
      <Toast
        showToast={Boolean(resMsg)}
        data={resMsg}
        closeToast={() => setResMsg(undefined)}
      />
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">
            HCI (Hyperconverged Infrastructure) Solutions
          </h1>

          <p className="subNavAbout mt10">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; Services
          </p>
        </div>
      </div>
      <div className="centerGrid80 aboutWhoWeAreCont mt15">
        <div className="hciContHeadCont">
          <p className="whoWeHeadTxt">Hyperconverged Infrastructure</p>
          <p className="whoWeHeadTxt">Solutions</p>

          <div className="whoWeUnderline" />
        </div>
        <div className="whoWeContCont">
          <p className="whoWeContTxt">
            Hyperconverged Infrastructure (HCI) is a cutting-edge approach to IT
            infrastructure that combines compute, storage, and networking into a
            single, highly optimized, and scalable system. Our HCI solutions
            simplify datacenter management, reduce operational complexity, and
            enable organizations to achieve greater agility and efficiency.
            <br />
          </p>
        </div>
      </div>
      <div className="serviceVdoCont desConOutCont mt20">
        <div className="centerGrid90 desConCont">
          <div className="servVdoLHS bgTrans">
            <p className="smallBoldTitles textGrey">Under HCI</p>

            <p className="whoWeHeadTxt textPrim">Main key features</p>
            <p className="whoWeHeadTxt textPrim">and benefits</p>
            <br />
            <img
              src={appImages.services.hciTestBanner}
              style={{
                width: "70%",
                height: "40%",
              }}
              alt="avancerImg"
            />
          </div>

          <div className="homeTestiContCont">
            {accordianData.map((accord, i) => {
              return (
                <Fragment>
                  <div
                    className="aboutUsAccorCont"
                    onClick={() => {
                      setAccordIndex(i);
                    }}
                  >
                    <p className="accordHeadText">{accord?.heading}</p>
                    {accordIndex !== i ? (
                      <ArrowDropDown className="aboutAccArrow" />
                    ) : (
                      <ArrowDropUp className="aboutAccArrow" />
                    )}
                  </div>
                  {accordIndex === i && (
                    <div className="accordContCont">
                      <p>{accord?.accordCont}</p>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className="approachCont">
        <div className="centerGrid60 mt20" style={{ display: "block" }}>
          <div className="approachLine"></div>
          <div className="approachContainer">
            <p
              className="aboutHeadingHome textAlignCenter mt10"
              style={{
                color: appColors.secondaryColor,
              }}
            >
              Unique approach
              <br />
              and features
            </p>
            <div className="approachContent">
              <p className="whoWeContTxt textAlignCenter">
                Hyperconverged Infrastructure (HCI) is a cutting-edge approach
                to IT infrastructure that combines compute, storage, and
                networking into a single, highly optimized, and scalable system.
                Our HCI solutions simplify datacenter management, reduce
                operational complexity, and enable organizations to achieve
                greater agility and efficiency.
                <br />
              </p>
            </div>
            <div className="approachLine"></div>
          </div>
        </div>
      </div>

      <p
        className="aboutHeadingHome textAlignCenter"
        style={{
          color: appColors.secondaryColor,
        }}
      >
        Design considerations
        <br />
        on HCI
      </p>

      <div className="considCardCont mt20">
        <div className="considCardBox">
          {[...designConsiderations].map((card, i) => {
            return (
              <div className="considCardItem" key={i}>
                <span className="considCardTitle">
                  <div className="considCardBg"></div>

                  <img
                    className="iconArrowCard"
                    src={appImages.appImages.appArrowLogo}
                    alt="avancerImg"
                  />
                  <div className="considCardItemTitle">{card?.title}</div>
                  <p className="considCardDesc">{card?.designDesc}</p>
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="considCardOutCont mt20">
        <p
          className="aboutHeadingHome textAlignCenter"
          style={{
            color: appColors.whiteColor,
          }}
        >
          Subservices we offer
          <br />
          under HCI
        </p>
        <div className="serviceNewCardCont">
          {subServices.map((subService, i) => {
            return (
              <div className="serviceNewCard">
                <div className="serviceNewCardHead">
                  <h5 className="newCardTitle">{subService?.title}</h5>
                  <p className="newCardCont">{subService?.content}</p>
                </div>
                <div className="serviceNewCardContent">
                  {subService.keyFeat.map((keyFeat, ind) => {
                    return (
                      <div className="innerServCardCont">
                        <ArrowRightAlt className="keysCardIcon" />
                        <h6 className="keyTitle">{keyFeat.title}</h6>
                        <p className="keyCont">{keyFeat.content}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="roundedArrowFab">
                  <img
                    className="iconArrowCardServ"
                    src={appImages.appImages.appArrowLogo}
                    alt="avancerImg"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <CitiesSlider slides={slides} /> */}

      <div
        className="centerGrid60 servFormCont mt20"
        style={{ justifyContent: "space-between" }}
      >
        <div className="servVdoLHS servFormLHS">
          <form>
            <input
              className="newLetterInput"
              placeholder="Name *"
              value={quoteDetails.name}
              onChange={(e) => {
                setDetails(e.target.value, "name");
              }}
            />
            {formValidators.formErrors(validationErrors.name)}

            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Email address *"
              value={quoteDetails.email}
              onChange={(e) => {
                setDetails(e.target.value, "email");
              }}
            />

            {formValidators.formErrors(validationErrors.email)}
            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Mobile *"
              value={quoteDetails.mobile}
              onChange={(e) => {
                setDetails(e.target.value, "mobile");
              }}
            />
            {formValidators.formErrors(validationErrors.mobile)}

            <input
              className="newLetterInput"
              style={{ marginTop: 25 }}
              placeholder="Subject *"
              value={quoteDetails.sub}
              onChange={(e) => {
                setDetails(e.target.value, "sub");
              }}
            />
            {formValidators.formErrors(validationErrors.sub)}

            <textarea
              className="newLetterInput"
              style={{ marginTop: 25, minHeight: 100 }}
              placeholder="Your message *"
              value={quoteDetails.message}
              onChange={(e) => {
                setDetails(e.target.value, "message");
              }}
            />
            {formValidators.formErrors(validationErrors.message)}

            <button
              disabled={Boolean(loading)}
              className="formSubmitBtn"
              onClick={(e) => sendDetails(e)}
              style={{ cursor: Boolean(loading) ? "not-allowed" : "pointer" }}
            >
              {loading ? "Submitting..." : "SEND MESSAGE"}
            </button>
          </form>
        </div>
        <div className="servVdoRHS letsWorkLHS">
          <p className="whoWeContHeadTxt">
            Let's work together on your next HCI Platform!
          </p>
          <p
            className="whoWeContHeadTxt"
            style={{ fontWeight: "normal", fontSize: 12 }}
          >
            Call us for immediate support to this number
          </p>
          <p className="whoWeContHeadTxt cp" onClick={handlePhoneNumberClick}>
            {appConstants.phoneNumber}
          </p>
          <div className="socialIcContServOut">
            {/* <div className="socialIcContServ ">
              <Instagram fontSize="small" className="socialIconServ" />
            </div>*/}
            <div
              className="socialIcContServ"
              onClick={() => {
                handleSendEmail();
              }}
            >
              <LocalPostOffice fontSize="small" className="socialIconServ" />
            </div>
            <div
              className="socialIcContServ"
              onClick={() => {
                openWhatsApp();
              }}
            >
              <WhatsApp fontSize="small" className="socialIconServ" />
            </div>
            <div
              className="socialIcContServ"
              onClick={() => {
                window.open(appConstants.linkedIn, "_blank");
              }}
            >
              <LinkedIn fontSize="small" className="socialIconServ" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRequestQuote: (requestData, onResponse) => {
      dispatch(createRequestQuote(requestData, onResponse));
    },
  };
};

export default connect(null, mapDispatchToProps)(HCITest);
