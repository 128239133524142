import Endpoints from "../../api/Endpoints";
import RequestService from "../../api/HandleApiRequest";
import * as Types from "../root.types";

export const getJobsList = (requestData, onResponse) => {
  return (dispatch) => {
    RequestService.get(Endpoints.RECRUITMENT_LIST, requestData, (response) => {
      onResponse(response);
    });
  };
};

export const getJobsDetails = (requestData, onResponse) => {
  return (dispatch) => {
    RequestService.get(
      Endpoints.RECRUITMENT_LIST + "/" + requestData?.jobId,
      null,
      (response) => {
        onResponse(response);
      }
    );
  };
};

export const isMenuOpen = (isMenuOpen) => {
  return {
    type: Types.IS_MENU_OPEN,
    payload: isMenuOpen,
  };
};
