import appConstants from "../appStrings/appConstants";
import appColors from "../themes/colorThemes";

const checkEmpty = (input) => {
  const isEmpty = Boolean(String(input).length === 0);
  return isEmpty;
};
const checkLength = (input, length) => {
  const isEmpty = Boolean(String(input).trim().length >= length);
  return isEmpty;
};

const checkEmail = (input) => {
  const isEmail = appConstants.emailRegEx.test(input);
  return isEmail;
};

const checkMobile = (input) => {
  const mobileNum = Number(input);

  const isMobile = appConstants.mobileRegEx.test(mobileNum);

  return isMobile;
};

const formErrors = (errorMsg) => {
  return errorMsg ? (
    <p
      style={{
        fontSize: 12,
        fontWeight: "bold",
        marginTop: 5,
        color: appColors.red,
      }}
    >
      {errorMsg}
    </p>
  ) : null;
};

const formValidators = {
  checkEmpty,
  checkLength,
  checkEmail,
  formErrors,
  checkMobile,
};
export default formValidators;
