import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./reducers/auth.reducers";
import appReducer from "./reducers/app.reducers";

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
  },
});

export default store;
