import React, { Fragment, useState } from "react";
import "../../../styles/layoutSass/home/base.scss";
import "../../../styles/layoutSass/about/base.scss";
import "../../../styles/layoutSass/services/base.scss";
import "../../../styles/layoutSass/home/login/_login.scss";
import "../../../styles/layoutSass/services/_hcitest.scss";

import appColors from "../../../utils/themes/colorThemes";
import appImages from "../../../assets";
import {
  ArrowDropDown,
  CheckCircle,
  ChevronRight,
  LinkedIn,
  LocalPostOffice,
  WarningAmberOutlined,
  WhatsApp,
} from "@mui/icons-material";
import formValidators from "../../../utils/commonFunctions/formValidators";
import Toast from "../../../components/Toast";
import { connect } from "react-redux";
import { createRequestQuote } from "../../../redux/actions/form.actions";
import { ArrowDropUp } from "@material-ui/icons";
import appConstants from "../../../utils/appStrings/appConstants";
import openWhatsApp, {
  handlePhoneNumberClick,
} from "../../../utils/commonFunctions/openWhatsapp";

const initialDetails = {
  name: "",
  email: "",
  mobile: "",
  sub: "",
  message: "",
};
const initialErrors = {
  name: false,
  email: false,
  mobile: "",
  sub: false,
  message: false,
};

const WebDev = (props) => {
  const effPoints = [
    {
      title: "Collaborative development",
      desc: `We work closely with you throughout the development process,
                  ensuring your vision is accurately translated into the final
                  product`,
    },

    {
      title: "Agile methodology",
      desc:
        "Our team follows an Agile development approach, enabling us to  adapt to changing requirements and deliver projects on time and within budget",
    },
    {
      title: "Latest technologies",
      desc:
        "We stay up-to-date with the latest web and app development technologies, ensuring your solutions are modern and future-proof",
    },
    {
      title: "End-to-end support",
      desc:
        "We provide end-to-end support, from planning and design to development, testing, deployment, and ongoing maintenance",
    },

    {
      title: "Training and awareness",
      desc:
        "We provide training and documentation to ensure that your team is prepared to execute the BCDR plan in the event of a disaster",
    },
  ];
  const accordianData = [
    {
      title: "Customized design",
      desc:
        "We create bespoke web and app designs that reflect your organization's brand, values, and target audience",
    },
    {
      title: "Responsive and accessible",
      desc:
        "Our solutions are designed for optimal performance across various devices, screen sizes, and platforms, ensuring a seamless user experience",
    },

    {
      title: "Scalability",
      desc:
        "Our web and app development solutions are built to scale with your organization's growth, allowing you to easily add features and functionalities as needed",
    },
    {
      title: "Security",
      desc:
        "We prioritize data protection and incorporate robust security measures into our web and app development projects",
    },
    {
      title: "Integration",
      desc:
        "Our solutions seamlessly integrate with your existing systems and third-party tools to streamline your operations and enhance collaboration",
    },
  ];

  const designConsiderations = [
    {
      title: "User Experience (UX)",
      designDesc:
        "We prioritize user experience, creating intuitive interfaces and easy-to-navigate designs that enhance user satisfaction and engagement",
    },
    {
      title: "User Interface (UI)",
      designDesc:
        "Our designs are visually appealing, incorporating your organization's branding elements and adhering to the latest UI design trends",
    },

    {
      title: "Performance Optimization",
      designDesc:
        "We optimize your web and app solutions for fast loading times and smooth performance, ensuring a seamless user experience.•Accessibility: Our designs follow accessibility best practices, making your web and app solutions usable for all users, including those with disabilities",
    },
    {
      title: "Accessibility",
      designDesc:
        "Our designs follow accessibility best practices, making your web and app solutions usable for all users, including those with disabilities",
    },
    {
      title: "Cross-Platform Compatibility",
      designDesc:
        "We develop web and app solutions that function flawlessly across various devices, operating systems, and browsers, ensuring a consistent user experience for all users",
    },
  ];

  const [quoteDetails, setQuoteDetails] = useState(initialDetails);
  const [validationErrors, setValdiationErrors] = useState(initialErrors);
  const [accordIndex, setAccordIndex] = useState(0);

  const [resMsg, setResMsg] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleSendEmail = () => {
    const email = appConstants.mail; // Replace with the recipient's email address
    const subject = "Request for quote"; // Replace with the desired subject line
    const body = "Hi avancerPI solutions, Shall we connect!"; // Replace with the desired email body

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  const setDetails = (value, key) => {
    setQuoteDetails({ ...quoteDetails, [key]: value });
    setValdiationErrors({ ...validationErrors, [key]: false });
  };
  const sendDetails = (e) => {
    e.preventDefault();
    const requestData = validateFields();
    if (requestData) {
      if (requestData) {
        setLoading(true);
        props.createRequestQuote(
          requestData,
          (res) => {
            const response = res;
            if (Number(response.statusCode) === 200) {
              setResMsg({
                icon: <CheckCircle style={{ color: appColors.whiteColor }} />,
                msg: response.message,
                bgColor: appColors.greenOverlay,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 3000);
              setValdiationErrors(initialErrors);
              setQuoteDetails(initialDetails);
              setLoading(false);
            } else {
              setResMsg({
                icon: (
                  <WarningAmberOutlined
                    style={{ color: appColors.whiteColor }}
                  />
                ),
                msg: "Oops! Something went wrong!",
                bgColor: appColors.red,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 2000);
              setLoading(false);
            }
          },
          false,
          false
        );
      }
    } else {
      setResMsg({
        icon: <WarningAmberOutlined style={{ color: appColors.whiteColor }} />,
        msg: "Check the details entered",
        bgColor: appColors.red,
      });
      setTimeout(() => {
        setResMsg(false);
      }, 2000);
      setLoading(false);
    }
  };

  const validateFields = () => {
    let formData = new FormData();
    let errors = { ...validationErrors };

    if (formValidators.checkEmpty(quoteDetails.name)) {
      errors.name = "Enter name";
      formData = undefined;
    } else {
      errors.name = false;
      formData?.append("name", quoteDetails.name);
    }

    if (formValidators.checkEmpty(quoteDetails.mobile)) {
      errors.mobile = "Enter mobile";
      formData = undefined;
    } else if (!formValidators.checkMobile(quoteDetails.mobile)) {
      errors.mobile = "Enter valid mobile";
      formData = undefined;
    } else {
      errors.mobile = false;
      formData?.append("mobile", quoteDetails.mobile);
    }
    if (formValidators.checkEmpty(quoteDetails.email)) {
      errors.email = "Enter email";
      formData = undefined;
    } else if (!formValidators.checkEmail(quoteDetails.email)) {
      errors.email = "Enter valid email";
      formData = undefined;
    } else {
      errors.email = false;
      formData?.append("email", quoteDetails.email);
    }
    if (formValidators.checkEmpty(quoteDetails.sub)) {
      errors.sub = "Enter subject";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.sub, 10)) {
      errors.sub = "Subject should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.sub = false;
      formData?.append("subject", quoteDetails.sub);
    }
    if (formValidators.checkEmpty(quoteDetails.message)) {
      errors.message = "Enter message";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.message, 10)) {
      errors.message = "Message should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.message = false;
      formData?.append("message", quoteDetails.message);
    }
    setValdiationErrors(errors);
    return formData;
  };

  return (
    <div className="mainContentSection">
      <Toast
        showToast={Boolean(resMsg)}
        data={resMsg}
        closeToast={() => setResMsg(undefined)}
      />
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"Web Development"}</h1>

          <p className="subNavAbout mt10">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; Services
          </p>
        </div>
      </div>
      <div className="centerGrid80 aboutWhoWeAreCont mt20">
        <div className="hciContHeadCont">
          <p className="whoWeHeadTxt">Web & App </p>
          <p className="whoWeHeadTxt">Development Solutions</p>

          <div className="whoWeUnderline" />
        </div>
        <div className="whoWeContCont">
          <p className="whoWeContTxt">
            We offer comprehensive web and app development solutions, creating
            engaging and user-friendly digital experiences tailored to your
            organization's unique requirements and goals. Our team of skilled
            developers follows the latest design principles and industry best
            practices to deliver innovative, high-quality websites and mobile
            applications.
            <br />
          </p>
        </div>
      </div>
      <div className="serviceVdoCont desConOutCont mt20">
        <div className="centerGrid90 desConCont">
          <div className="servVdoLHS bgTrans">
            <p className="smallBoldTitles textGrey">Under App Development</p>

            <p className="whoWeHeadTxt textPrim">Main key features</p>
            <p className="whoWeHeadTxt textPrim">and benefits</p>
            <br />
            <img
              src={appImages.services.hciTestBanner}
              style={{
                width: "70%",
                height: "40%",
              }}
              alt="avancerImg"
            />
          </div>

          <div className="homeTestiContCont">
            {accordianData.map((accord, i) => {
              return (
                <Fragment>
                  <div
                    className="aboutUsAccorCont"
                    onClick={() => {
                      setAccordIndex(i);
                    }}
                  >
                    <p className="accordHeadText">{accord?.title}</p>
                    {accordIndex !== i ? (
                      <ArrowDropDown className="aboutAccArrow" />
                    ) : (
                      <ArrowDropUp className="aboutAccArrow" />
                    )}
                  </div>
                  {accordIndex === i && (
                    <div className="accordContCont">
                      <p>{accord?.desc}</p>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className="homeAboutCont centerGrid80">
        <div className="homeAboutImgCont">
          <img
            className="homeAboutImg"
            alt="avancerAboutImg"
            src={appImages.homeImages.Aboutus}
          />
        </div>
        <div className="homeAboutContentsCont mt20">
          <div className="homeAboutLine"></div>
          <p className="homeAboutTxt aboutHeadingHome">
            Unique approaches
            <br />
            and features
          </p>
          <div className="aboutDetailsHomeCont">
            <span>
              <ul className="homeAboutList">
                {effPoints.map((content, i) => {
                  return (
                    <li className="aboutInnList" key={i}>
                      <CheckCircle className="aboutListIcon" />
                      <p className="aboutInnContent">
                        <span style={{ fontWeight: "700" }}>
                          {content?.title}
                        </span>{" "}
                        - {content?.desc}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </span>
          </div>
        </div>
      </div>

      <div
        className="approachContainer"
        style={{
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <p
          className="aboutHeadingHome textAlignCenter"
          style={{
            color: appColors.secondaryColor,
          }}
        >
          Design Considerations for
          <br />
          Disaster Recovery Solutions
        </p>
      </div>
      <div className="considCardCont">
        <div className="considCardBox">
          {[...designConsiderations].map((card, i) => {
            return (
              <div className="considCardItem" key={i}>
                <span className="considCardTitle">
                  <div className="considCardBg"></div>

                  <img
                    className="iconArrowCard"
                    src={appImages.appImages.appArrowLogo}
                    alt="avancerImg"
                  />
                  <div className="considCardItemTitle">{card?.title}</div>
                  <p className="considCardDesc">{card?.designDesc}</p>
                </span>
              </div>
            );
          })}
        </div>
      </div>

      {/* <CitiesSlider slides={slides} /> */}

      <div
        className="centerGrid60 servFormCont mt20"
        style={{ justifyContent: "space-between" }}
      >
        <div className="servVdoLHS servFormLHS">
          <form>
            <input
              className="newLetterInput"
              placeholder="Name *"
              value={quoteDetails.name}
              onChange={(e) => {
                setDetails(e.target.value, "name");
              }}
            />
            {formValidators.formErrors(validationErrors.name)}

            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Email address *"
              value={quoteDetails.email}
              onChange={(e) => {
                setDetails(e.target.value, "email");
              }}
            />

            {formValidators.formErrors(validationErrors.email)}
            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Mobile *"
              value={quoteDetails.mobile}
              onChange={(e) => {
                setDetails(e.target.value, "mobile");
              }}
            />
            {formValidators.formErrors(validationErrors.mobile)}

            <input
              className="newLetterInput"
              style={{ marginTop: 25 }}
              placeholder="Subject *"
              value={quoteDetails.sub}
              onChange={(e) => {
                setDetails(e.target.value, "sub");
              }}
            />
            {formValidators.formErrors(validationErrors.sub)}

            <textarea
              className="newLetterInput"
              style={{ marginTop: 25, minHeight: 100 }}
              placeholder="Your message *"
              value={quoteDetails.message}
              onChange={(e) => {
                setDetails(e.target.value, "message");
              }}
            />
            {formValidators.formErrors(validationErrors.message)}

            <button
              disabled={Boolean(loading)}
              className="formSubmitBtn"
              onClick={(e) => sendDetails(e)}
              style={{ cursor: Boolean(loading) ? "not-allowed" : "pointer" }}
            >
              {loading ? "Submitting..." : "SEND MESSAGE"}
            </button>
          </form>
        </div>
        <div className="servVdoRHS letsWorkLHS">
          <p className="whoWeContHeadTxt">
            Let's work together on your next Web Development Platform!
          </p>
          <p
            className="whoWeContHeadTxt"
            style={{ fontWeight: "normal", fontSize: 12 }}
          >
            Call us for immediate support to this number
          </p>
          <p className="whoWeContHeadTxt cp" onClick={handlePhoneNumberClick}>
            {appConstants.phoneNumber}
          </p>
          <div className="socialIcContServOut">
            <div
              className="socialIcContServ"
              onClick={() => {
                handleSendEmail();
              }}
            >
              <LocalPostOffice fontSize="small" className="socialIconServ" />
            </div>
            <div
              className="socialIcContServ"
              onClick={() => {
                openWhatsApp();
              }}
            >
              <WhatsApp fontSize="small" className="socialIconServ" />
            </div>
            <div
              className="socialIcContServ"
              onClick={() => {
                window.open(appConstants.linkedIn, "_blank");
              }}
            >
              <LinkedIn fontSize="small" className="socialIconServ" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRequestQuote: (requestData, onResponse) => {
      dispatch(createRequestQuote(requestData, onResponse));
    },
  };
};

export default connect(null, mapDispatchToProps)(WebDev);
