import { Provider } from "react-redux";
import MainNavigation from "./navigations/MainNavigation";
import store from "./redux/store";

function App() {
  return (
    <Provider store={store}>
      
        <MainNavigation />
    
    </Provider>
  );
}

export default App;
