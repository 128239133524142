import React from "react";
import appImages from "../../assets";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import appColors from "../../utils/themes/colorThemes";
// import FontAwesome from "react-fontawesome";

import {
  KeyboardArrowDown,
  LocalPostOffice,
  LocalPhone,
  LinkedIn,
  WhatsApp,
} from "@mui/icons-material";
import appConstants from "../../utils/appStrings/appConstants";
import openWhatsApp, {
  handlePhoneNumberClick,
} from "../../utils/commonFunctions/openWhatsapp";

const HeaderWindow = (props) => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const subServices = [
    {
      name: "HCI Data center",
      navigationRoute: "/HCI",
    },
    {
      name: "Workload migration",
      navigationRoute: "/WorkLoadMigration",
    },
    {
      name: "Disaster Recovery",
      navigationRoute: "/DisasterRecovery",
    },
    {
      name: "Cloud Automation",
      navigationRoute: "/CloudAutomation",
    },
    // {
    //   name: "App Modernization",
    //   navigationRoute: "/AppModernization",
    // },

    {
      name: "App development",
      navigationRoute: "/AppDevelopment",
    },
  ];

  const handleSendEmail = () => {
    const email = appConstants.mail; // Replace with the recipient's email address
    const subject = "Request for quote"; // Replace with the desired subject line
    const body = "Hi avancerPI solutions, Shall we connect!"; // Replace with the desired email body

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };
  return (
    <div className="windowHeaderContainer" id="windowHeader">
      <div className="contactHeaderHomeCont">
        <div
          className="socialHeaderInfoCont centerGrid80"
          style={{ marginTop: "0px" }}
        >
          <div className="headInfoCont">
            <span className="dfac cp" onClick={handleSendEmail}>
              <LocalPostOffice fontSize="10px" className="headerInfoIcon" />
              <p>
                &nbsp;&nbsp;&nbsp;{appConstants.mail} &nbsp;&nbsp;&nbsp;|
                &nbsp;&nbsp;&nbsp;
              </p>
            </span>
            <span className="dfac cp" onClick={handlePhoneNumberClick}>
              <LocalPhone fontSize="10px" className="headerInfoIcon" />
              <p> &nbsp;&nbsp;&nbsp;{appConstants.phoneNumber} </p>
            </span>
          </div>
          <div className="headInfoCont">
            {/* <Facebook fontSize="10px" className="headerSocialIcon" />
            <Instagram fontSize="10px" className="headerSocialIcon" />
            
            <Twitter fontSize="10px" className="headerSocialIcon" /> */}
            <WhatsApp
              fontSize="10px"
              className="headerSocialIcon"
              onClick={() => {
                openWhatsApp();
              }}
              style={{ cursor: "pointer" }}
            />
            <LinkedIn
              fontSize="10px"
              className="headerSocialIcon"
              onClick={() => {
                window.open(appConstants.linkedIn, "_blank");
              }}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
      <div className="headerOutWin">
        <div
          className="headerContentsCont centerGrid80"
          style={{ marginTop: "0px" }}
        >
          <div className="container1">
            <img
              src={appImages.appImages.logo}
              className="headerLogoWin"
              alt="avancerPIAppLogo"
            />
          </div>
          <div className="container2">
            <ul className="navbar">
              <li style={{ left: "-20px" }} className="navbarChild">
                <Link
                  className={`screenLinks ${
                    currentRoute === "/" || currentRoute === "/home"
                      ? "activeNavColor"
                      : ""
                  }`}
                  to="/"
                >
                  Home
                </Link>
              </li>

              <li className="navbarChild">
                <Link
                  className={`screenLinks ${
                    currentRoute === "/About" ? "activeNavColor" : ""
                  }`}
                  to="/About"
                >
                  About
                </Link>
              </li>

              <li className="subMenu navbarChild">
                <Link
                  onClick={() => {
                    document.getElementsByClassName(
                      "innerSubList"
                    )[0].style.display = "flex";
                  }}
                  onMouseOver={() => {
                    document.getElementsByClassName(
                      "innerSubList"
                    )[0].style.display = "flex";
                  }}
                  onMouseOut={() => {
                    document.getElementsByClassName(
                      "innerSubList"
                    )[0].style.display = "none";
                  }}
                  className={`screenLinks ${
                    currentRoute === "/Services" ||
                    currentRoute === "/DataCenter" ||
                    currentRoute === "/DataCenterSubServices" ||
                    currentRoute === "/WebDev" ||
                    currentRoute === "/AppModernization" ||
                    currentRoute === "/CloudAutomation" ||
                    currentRoute === "/DisasterRecovery" ||
                    currentRoute === "/WorkLoadMigration"
                      ? "activeNavColor"
                      : ""
                  } subServ`}
                  to="/Services"
                >
                  Services
                  <KeyboardArrowDown
                    style={{
                      marginLeft: 5,
                      fontSize: 20,
                      color: appColors.primaryColor,
                    }}
                  />
                </Link>
                <ul className="innerSubList">
                  {subServices.map((subService, i) => {
                    return (
                      <li
                        onMouseOver={() => {
                          document.getElementsByClassName(
                            "innerSubList"
                          )[0].style.display = "flex";
                        }}
                        onMouseOut={() => {
                          document.getElementsByClassName(
                            "innerSubList"
                          )[0].style.display = "none";
                        }}
                        onClick={() => {
                          document.getElementsByClassName(
                            "innerSubList"
                          )[0].style.display = "none";
                        }}
                        key={i}
                        className="navInList"
                      >
                        <Link
                          to={subService.navigationRoute}
                          className="menuHeading"
                        >
                          {subService.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>

              <li className="navbarChild">
                <Link
                  className={`screenLinks ${
                    currentRoute === "/Career" ? "activeNavColor" : ""
                  }`}
                  to="/Career"
                >
                  Career
                </Link>
              </li>
              <li className="navbarChild">
                <Link
                  className={`screenLinks ${
                    currentRoute === "/ContactUs" ? "activeNavColor" : ""
                  }`}
                  to="/Contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div className="headerLoginBtn">
            <p style={{ color: "white", fontSize: 14 }}>Login</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderWindow;
