import React, { useLayoutEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import store from "../redux/store";
import { isMenuOpen } from "../redux/actions/app.actions";

const SiteLayout = ({ children }) => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(0);
  const myStore = store.getState();
  const [menuOpen, setMenuOpen] = useState(myStore?.auth?.isMenuOpen || false);

  // React.useEffect(() => {
  //   let isActive = true;
  //   isActive && handleSize(window.innerWidth, window.innerHeight);
  //   isActive && window.addEventListener("resize", getCurrentScreenSize);
  //   return () => {
  //     isActive = false;
  //   };
  // }, []);

  // const getCurrentScreenSize = (e) => {
  //   const windowWidth = window.innerWidth;
  //   const windowHeight = window.innerHeight;
  //   handleSize(windowWidth, windowHeight);
  // };
  // const handleSize = (currentWidth, currentHeight) => {
  //   setCurrentWidth(currentWidth);

  //   if (currentWidth >= 1000) {
  //     setIsMobileDevice(false);
  //   } else if (currentWidth < 1000) {
  //     setIsMobileDevice(true);
  //   }
  // };
  const [deviceInfo, setDeviceInfo] = useState(false);

  function checkIsMobile() {
    const isMobile = window.innerWidth <= 1000 ? true : false;

    if (isMobile) {
      console.log(isMobile, "isMobile");
      setDeviceInfo(true);
    } else {
      setDeviceInfo(false);
    }
  }
  React.useEffect(() => {
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.addEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <div>
      <Header
        mobOpen={menuOpen}
        isMobileDevice={deviceInfo}
        currentWidth={currentWidth}
      />
      <main style={{ marginBottom: "50px" }}>{children}</main>
      <Footer />
    </div>
  );
};

export default SiteLayout;
