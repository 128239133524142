import Fetch from "./Fetch";
import * as URL from "./BaseUrl";

class HandleRequestService {
  delete = (endpoint, onResponse, baseManagement = false) => {
    handleRequestHeaders("DELETE", endpoint, null, onResponse, baseManagement);
  };

  get = (endpoint, requestData, onResponse, baseManagement = false) => {
    handleRequestHeaders(
      "GET",
      endpoint,
      requestData,
      onResponse,
      baseManagement
    );
  };
  post = (endpoint, requestData, onResponse, baseManagement = false) => {
    // console.log(requestData, "FormData::::::::");
    handleRequestHeaders(
      "POST",
      endpoint,
      requestData,
      onResponse,

      baseManagement
    );
  };
}

const handleRequestHeaders = async (
  methodType,
  endpoint,
  requestData,
  onResponse,

  baseManagement
) => {
  let jwtToken = undefined;
  // const jwtToken = JSON.parse(await AsyncStorage.getItem(TOKEN))
  // const jwtTokenEcom = JSON.parse(await AsyncStorage.getItem(ECOM_TOKEN))

  // console.log("JWT Token:::::::::::: ", jwtToken);

  let requestHeader = {
    method: methodType,
    // headers: jwtToken
    //   ? {
    //       Accept: "*/*",
    //       "Content-Type":
    //         requestData instanceof FormData
    //           ? "multipart/form-data"
    //           : "application/json",
    //       Authorization: "Bearer" + " " + jwtToken,
    //     }
    //   : {
    //       Accept: "*/*",
    //       "Content-Type":
    //         requestData instanceof FormData
    //           ? "multipart/form-data"
    //           : "application/json",
    //     },
  };

  methodType !== "GET" &&
    Boolean(requestData) &&
    (requestHeader = {
      ...requestHeader,
      body:
        requestData instanceof FormData
          ? requestData
          : JSON.stringify(requestData),
    });
  if (methodType == "GET" && requestData) {
    Fetch.handleFetchRequest(
      `${URL.BASE_URL}${endpoint}`.concat(requestData),
      requestHeader,
      onResponse
    );
  } else {
    Fetch.handleFetchRequest(
      `${URL.BASE_URL}${endpoint}`,
      requestHeader,
      onResponse
    );
  }
};

const HandleApiRequest = new HandleRequestService();

export default HandleApiRequest;
