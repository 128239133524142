import React from "react";

class HandleFetchRequest {
  handleFetchRequest = (endpoint, headers, onResponse) => {
    console.log("REQUEST ENDPOINT::: ", endpoint);
    console.log("REQUEST HEADERS::: ", headers);

    fetch(endpoint, headers)
      .then(handleResponse)
      .then((successResponse) => {
        console.log("SUCCESS RESPONSE::: ", successResponse);

        handleSuccessResponse(successResponse, onResponse);
      })
      .catch((errorResponse) => {
        console.log("ERROR RESPONSE::: ", errorResponse);
        handleErrorResponse(errorResponse, onResponse);
      });
  };
}

const handleResponse = async (response) => {
  const resJson = response.json();
  const statusCode = response.status;
  return Promise.all([statusCode, resJson]).then((res) => ({
    statusCode: res[0],
    resJson: res[1],
  }));
};

const handleSuccessResponse = (successResponse, onResponse) => {
  const response = successResponse.resJson;
  if (Number(successResponse?.statusCode) === 200) {
    if (response.success === false) {
      onResponse(response);
    } else {
      onResponse(response);
    }
  } else if (successResponse?.statusCode == 401) {
    onResponse(response);
  } else {
    onResponse(response);
  }
};

const handleErrorResponse = async (errorResponse, onResponse) => {
  const response = errorResponse.message;

  if (typeof response == "string" && response.includes("JSON Parse error")) {
    onResponse({
      data: [],
      message: "Oops! Something went wrong",
      statusCode: 500,
    });
  } else {
    onResponse({
      data: [],
      message: "Oops! Something went wrong",
      statusCode: 500,
    });
  }
};

const FetchRequest = new HandleFetchRequest();

export default FetchRequest;
