import appConstants from "../appStrings/appConstants";
const phoneNumber = appConstants.phoneNumber; // Replace with the desired phone number
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const openWhatsApp = () => {
  const message = encodeURIComponent("Hello avancerPI Solutions Team!"); // Replace with your desired message

  if (isMobile) {
    // Open WhatsApp mobile app
    window.location.href = `whatsapp://send?phone=${phoneNumber}&text=${message}`;
  } else {
    // Open WhatsApp Web
    window.open(
      `https://web.whatsapp.com/send?phone=${phoneNumber}&text=${message}`
    );
  }
};

export const handlePhoneNumberClick = () => {
  window.location.href = `tel:${phoneNumber}`;
};

export default openWhatsApp;
