import React, { Suspense, lazy } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import SiteLayout from '../../layouts/SiteLayout'
import Login from '../../screens/mainScreens/Login'
// import Home from '../../screens/mainScreens/Home'
import AppRoute from '../AppRoute'
import NoScreen from '../../screens/mainScreens/NoScreen'
import AboutUs from '../../screens/mainScreens/AboutUs'
import HCI from '../../screens/mainScreens/services/HCI'
import ContactUs from '../../screens/mainScreens/ContactUs'
import WorkLoadMigration from '../../screens/mainScreens/services/WorkLoadMigration'
import DisRec from '../../screens/mainScreens/services/DisRec'
import CloudAutomation from '../../screens/mainScreens/services/CloudAutomation'
import WebDev from '../../screens/mainScreens/services/WebDev'
import Career from '../../screens/mainScreens/Career'
import ScrollToTop from '../../components/ScrollToTop'
import CareerDesc from '../../screens/mainScreens/CareerDesc'
import Services from '../../screens/mainScreens/services/Services'
import appImages from '../../assets'

const Home = lazy(() => import('../../screens/mainScreens/Home'))

const SiteNav = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes base='https://testweb.avancerpi.com/'>
          <Route
            exact
            path='/'
            element={
              <Suspense
                fallback={
                  <div style={{ height: '100%', height: '100%' }}>
                    <img alt='appLoader' src={appImages.logos.logoLoader} />
                  </div>
                }
              >
                <AppRoute component={Home} layout={SiteLayout} />
              </Suspense>
            }
          ></Route>
          <Route
            exact
            path='/home'
            element={
              <Suspense
                fallback={
                  <div style={{ height: '100%', height: '100%' }}>
                    <img alt='appLoader' src={appImages.logos.logoLoader} />
                  </div>
                }
              >
                <AppRoute component={Home} layout={SiteLayout} />
              </Suspense>
            }
          ></Route>
          <Route
            exact
            path='/services'
            element={<AppRoute component={Services} layout={SiteLayout} />}
          ></Route>
          <Route
            exact
            path='/login'
            element={<AppRoute component={Login} layout={SiteLayout} />}
          ></Route>

          <Route
            exact
            path='/about'
            element={<AppRoute component={AboutUs} layout={SiteLayout} />}
          ></Route>

          <Route
            exact
            path='/HCI'
            element={<AppRoute component={HCI} layout={SiteLayout} />}
          ></Route>
          {/* <Route
          exact
          path="/HCITest"
          element={<AppRoute component={HCITest} layout={SiteLayout} />}
        ></Route> */}
          {/* <Route
          exact
          path="/card"
          element={<AppRoute component={CardTest} layout={SiteLayout} />}
        ></Route> */}
          <Route
            exact
            path='/WorkLoadMigration'
            element={
              <AppRoute component={WorkLoadMigration} layout={SiteLayout} />
            }
          ></Route>
          <Route
            exact
            path='/DisasterRecovery'
            element={<AppRoute component={DisRec} layout={SiteLayout} />}
          ></Route>
          <Route
            exact
            path='/CloudAutomation'
            element={
              <AppRoute component={CloudAutomation} layout={SiteLayout} />
            }
          ></Route>
          {/* <Route
          exact
          path="/AppModernization"
          element={<AppRoute component={AppModern} layout={SiteLayout} />}
        ></Route> */}
          <Route
            exact
            path='/AppDevelopment'
            element={<AppRoute component={WebDev} layout={SiteLayout} />}
          ></Route>
          <Route
            exact
            path='/Career'
            element={<AppRoute component={Career} layout={SiteLayout} />}
          ></Route>
          <Route
            exact
            path='/CareerDetails/:jobId'
            element={<AppRoute component={CareerDesc} layout={SiteLayout} />}
          ></Route>
          <Route
            exact
            path='/Contact'
            element={<AppRoute component={ContactUs} layout={SiteLayout} />}
          ></Route>
          <Route
            exact
            path='*'
            element={<AppRoute component={NoScreen} layout={SiteLayout} />}
          ></Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default SiteNav
