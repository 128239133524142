import * as Types from "../root.types";

const initialState = {
  isLoggedIn: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.IS_LOGGEDIN:
      return { ...state, isLoggedIn: action.payload };

    default:
      return state;
  }
};

export default authReducer;
