import React, { Fragment, useState } from "react";
import "../../../styles/layoutSass/home/base.scss";
import "../../../styles/layoutSass/about/base.scss";
import "../../../styles/layoutSass/services/base.scss";
import "../../../styles/layoutSass/home/login/_login.scss";
import "../../../styles/layoutSass/services/_hcitest.scss";

import appColors from "../../../utils/themes/colorThemes";
import appImages from "../../../assets";
import {
  ArrowDropDown,
  CheckCircle,
  ChevronRight,
  LinkedIn,
  LocalPostOffice,
  WarningAmberOutlined,
  WhatsApp,
} from "@mui/icons-material";
import formValidators from "../../../utils/commonFunctions/formValidators";
import Toast from "../../../components/Toast";
import { connect } from "react-redux";
import { createRequestQuote } from "../../../redux/actions/form.actions";
import { ArrowDropUp } from "@material-ui/icons";
import appConstants from "../../../utils/appStrings/appConstants";
import openWhatsApp, {
  handlePhoneNumberClick,
} from "../../../utils/commonFunctions/openWhatsapp";

const initialDetails = {
  name: "",
  email: "",
  mobile: "",
  sub: "",
  message: "",
};
const initialErrors = {
  name: false,
  email: false,
  mobile: "",
  sub: false,
  message: false,
};

const DisRec = (props) => {
  const effPoints = [
    {
      title: "Comprehensive risk assessment",
      desc:
        "Our team conducts a thorough risk assessment to identify potential threats, vulnerabilities, and impacts on your business operations and data",
    },

    {
      title: "Design considerations",
      desc:
        "We develop BCDR solutions based on design considerations for disaster recovery, such as RTO, RPO, fault tolerance, redundancy, and scalability",
    },
    {
      title: "Business impact analysis",
      desc:
        "We perform a business impact analysis to prioritize critical functions and establish recovery objectives",
    },
    {
      title: "Continuous improvement",
      desc:
        "We regularly review and update your BCDR solution to keep it aligned with changing business needs, industry best practices, and design considerations",
    },

    {
      title: "Training and awareness",
      desc:
        "We provide training and documentation to ensure that your team is prepared to execute the BCDR plan in the event of a disaster",
    },
  ];
  const accordianData = [
    {
      title: "Customized BCDR plans",
      desc:
        "We develop tailored business continuity and disaster recovery plans based on your unique requirements, infrastructure, and industry standards",
    },
    {
      title: "Rapid recovery",
      desc:
        "Our solutions prioritize quick recovery times to minimize downtime and reduce the impact on your business operations",
    },
    {
      title: "Data protection",
      desc:
        "We safeguard your critical data through backup, replication, and encryption technologies, ensuring data integrity and security",
    },
    {
      title: "Compliance : ",
      desc:
        "Our team ensures that your BCDR solution meets regulatory and industry-specific requirements",
    },
    {
      title: "Regular testing and updates",
      desc:
        "We conduct routine tests and updates to ensure that your BCDR plan remains effective and up-to-date in the face of changing threats and business needs",
    },
  ];
  const [accordIndex, setAccordIndex] = useState(0);

  const designConsiderations = [
    {
      title: "Recovery Time Objective (RTO)",
      designDesc:
        "Determine the maximum acceptable time it takes to restore operations after a disaster",
    },
    {
      title: "Recovery Point Objective (RPO)",
      designDesc:
        "Establish the maximum acceptable data loss in terms of time that can be tolerated",
    },
    {
      title: "Fault tolerance",
      designDesc:
        "Design the solution to withstand component failures without service interruption",
    },
    {
      title: "Redundancy",
      designDesc:
        "Implement redundancy at every level, including hardware, networking, and storage, to ensure continuous operation during a disaster",
    },

    {
      title: "Geographic diversity",
      designDesc:
        "Locate backup and recovery sites at a safe distance from the primary site to minimize the impact of regional disasters",
    },
    {
      title: "Data replication",
      designDesc:
        "Choose appropriate replication technologies and methods to maintain up-to-date copies of data at the recovery site",
    },
    {
      title: "Backup and restore",
      designDesc:
        "Implement efficient backup and restore processes to minimize data loss and recovery time",
    },
    {
      title: "Monitoring and alerting",
      designDesc:
        "Set up monitoring and alerting systems to detect and notify of potential threats and disasters",
    },
    {
      title: "Security and compliance",
      designDesc:
        "Incorporate security measures, such as encryption and access controls, to protect data and ensure compliance with relevant regulations",
    },
    {
      title: "Testing and validation",
      designDesc:
        "Conduct regular tests and validation exercises to ensure the disaster recovery solution is effective and meets recovery objectives",
    },
  ];

  const [quoteDetails, setQuoteDetails] = useState(initialDetails);
  const [validationErrors, setValdiationErrors] = useState(initialErrors);

  const [resMsg, setResMsg] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const handleSendEmail = () => {
    const email = appConstants.mail; // Replace with the recipient's email address
    const subject = "Request for quote"; // Replace with the desired subject line
    const body = "Hi avancerPI solutions, Shall we connect!"; // Replace with the desired email body

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };
  const setDetails = (value, key) => {
    setQuoteDetails({ ...quoteDetails, [key]: value });
    setValdiationErrors({ ...validationErrors, [key]: false });
  };
  const sendDetails = (e) => {
    e.preventDefault();
    const requestData = validateFields();
    if (requestData) {
      if (requestData) {
        setLoading(true);
        props.createRequestQuote(
          requestData,
          (res) => {
            const response = res;
            if (Number(response.statusCode) === 200) {
              setResMsg({
                icon: <CheckCircle style={{ color: appColors.whiteColor }} />,
                msg: response.message,
                bgColor: appColors.greenOverlay,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 3000);
              setValdiationErrors(initialErrors);
              setQuoteDetails(initialDetails);
              setLoading(false);
            } else {
              setResMsg({
                icon: (
                  <WarningAmberOutlined
                    style={{ color: appColors.whiteColor }}
                  />
                ),
                msg: "Oops! Something went wrong!",
                bgColor: appColors.red,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 2000);
              setLoading(false);
            }
          },
          false,
          false
        );
      }
    } else {
      setResMsg({
        icon: <WarningAmberOutlined style={{ color: appColors.whiteColor }} />,
        msg: "Check the details entered",
        bgColor: appColors.red,
      });
      setTimeout(() => {
        setResMsg(false);
      }, 2000);
      setLoading(false);
    }
  };

  const validateFields = () => {
    let formData = new FormData();
    let errors = { ...validationErrors };

    if (formValidators.checkEmpty(quoteDetails.name)) {
      errors.name = "Enter name";
      formData = undefined;
    } else {
      errors.name = false;
      formData?.append("name", quoteDetails.name);
    }

    if (formValidators.checkEmpty(quoteDetails.mobile)) {
      errors.mobile = "Enter mobile";
      formData = undefined;
    } else if (!formValidators.checkMobile(quoteDetails.mobile)) {
      errors.mobile = "Enter valid mobile";
      formData = undefined;
    } else {
      errors.mobile = false;
      formData?.append("mobile", quoteDetails.mobile);
    }
    if (formValidators.checkEmpty(quoteDetails.email)) {
      errors.email = "Enter email";
      formData = undefined;
    } else if (!formValidators.checkEmail(quoteDetails.email)) {
      errors.email = "Enter valid email";
      formData = undefined;
    } else {
      errors.email = false;
      formData?.append("email", quoteDetails.email);
    }
    if (formValidators.checkEmpty(quoteDetails.sub)) {
      errors.sub = "Enter subject";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.sub, 10)) {
      errors.sub = "Subject should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.sub = false;
      formData?.append("subject", quoteDetails.sub);
    }
    if (formValidators.checkEmpty(quoteDetails.message)) {
      errors.message = "Enter message";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.message, 10)) {
      errors.message = "Message should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.message = false;
      formData?.append("message", quoteDetails.message);
    }
    setValdiationErrors(errors);
    return formData;
  };

  return (
    <div className="mainContentSection">
      <Toast
        showToast={Boolean(resMsg)}
        data={resMsg}
        closeToast={() => setResMsg(undefined)}
      />
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"Disaster Recovery"}</h1>

          <p className="subNavAbout mt10">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; Services
          </p>
        </div>
      </div>
      <div className="centerGrid80 aboutWhoWeAreCont mt20">
        <div className="hciContHeadCont">
          <p className="whoWeHeadTxt">Business Continuity & </p>
          <p className="whoWeHeadTxt">Disaster Recovery Solutions</p>

          <div className="whoWeUnderline" />
        </div>
        <div className="whoWeContCont">
          <p className="whoWeContTxt">
            Our business continuity and disaster recovery (BCDR) solutions
            provide organizations with comprehensive strategies and plans to
            protect their critical business functions and data during unforeseen
            events. We design and implement robust BCDR solutions that minimize
            downtime, ensure quick recovery, and maintain data integrity while
            adhering to industry best practices and design considerations.
            <br />
          </p>
        </div>
      </div>
      <div className="serviceVdoCont desConOutCont mt20">
        <div className="centerGrid90 desConCont">
          <div className="servVdoLHS bgTrans">
            <p className="smallBoldTitles textGrey">Under Disaster Recovery</p>

            <p className="whoWeHeadTxt textPrim">Main key features</p>
            <p className="whoWeHeadTxt textPrim">and benefits</p>
            <br />
            <img
              src={appImages.services.hciTestBanner}
              style={{
                width: "70%",
                height: "40%",
              }}
              alt="avancerImg"
            />
          </div>

          <div className="homeTestiContCont">
            {accordianData.map((accord, i) => {
              return (
                <Fragment>
                  <div
                    className="aboutUsAccorCont"
                    onClick={() => {
                      setAccordIndex(i);
                    }}
                  >
                    <p className="accordHeadText">{accord?.title}</p>
                    {accordIndex !== i ? (
                      <ArrowDropDown className="aboutAccArrow" />
                    ) : (
                      <ArrowDropUp className="aboutAccArrow" />
                    )}
                  </div>
                  {accordIndex === i && (
                    <div className="accordContCont">
                      <p>{accord?.desc}</p>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className="homeAboutCont centerGrid80">
        <div className="homeAboutImgCont">
          <img
            className="homeAboutImg"
            alt="avancerAboutImg"
            src={appImages.homeImages.Aboutus}
          />
        </div>
        <div className="homeAboutContentsCont mt20">
          <div className="homeAboutLine"></div>
          <p className="homeAboutTxt aboutHeadingHome">
            Unique approaches
            <br />
            and features
          </p>
          <div className="aboutDetailsHomeCont">
            <span>
              <ul className="homeAboutList">
                {effPoints.map((content, i) => {
                  return (
                    <li className="aboutInnList" key={i}>
                      <CheckCircle className="aboutListIcon" />
                      <p className="aboutInnContent">
                        <span style={{ fontWeight: "700" }}>
                          {content?.title}
                        </span>{" "}
                        - {content?.desc}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </span>
          </div>
        </div>
      </div>

      <div
        className="approachContainer"
        style={{
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <p
          className="aboutHeadingHome textAlignCenter"
          style={{
            color: appColors.secondaryColor,
          }}
        >
          Design Considerations for
          <br />
          Disaster Recovery Solutions
        </p>
      </div>
      <div className="considCardCont">
        <div className="considCardBox">
          {[...designConsiderations].map((card, i) => {
            return (
              <div className="considCardItem" key={i}>
                <span className="considCardTitle">
                  <div className="considCardBg"></div>

                  <img
                    className="iconArrowCard"
                    src={appImages.appImages.appArrowLogo}
                    alt="avancerImg"
                  />
                  <div className="considCardItemTitle">{card?.title}</div>
                  <p className="considCardDesc">{card?.designDesc}</p>
                </span>
              </div>
            );
          })}
        </div>
      </div>

      {/* <CitiesSlider slides={slides} /> */}

      <div
        className="centerGrid60 servFormCont mt20"
        style={{ justifyContent: "space-between" }}
      >
        <div className="servVdoLHS servFormLHS">
          <form>
            <input
              className="newLetterInput"
              placeholder="Name *"
              value={quoteDetails.name}
              onChange={(e) => {
                setDetails(e.target.value, "name");
              }}
            />
            {formValidators.formErrors(validationErrors.name)}

            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Email address *"
              value={quoteDetails.email}
              onChange={(e) => {
                setDetails(e.target.value, "email");
              }}
            />

            {formValidators.formErrors(validationErrors.email)}
            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Mobile *"
              value={quoteDetails.mobile}
              onChange={(e) => {
                setDetails(e.target.value, "mobile");
              }}
            />
            {formValidators.formErrors(validationErrors.mobile)}

            <input
              className="newLetterInput"
              style={{ marginTop: 25 }}
              placeholder="Subject *"
              value={quoteDetails.sub}
              onChange={(e) => {
                setDetails(e.target.value, "sub");
              }}
            />
            {formValidators.formErrors(validationErrors.sub)}

            <textarea
              className="newLetterInput"
              style={{ marginTop: 25, minHeight: 100 }}
              placeholder="Your message *"
              value={quoteDetails.message}
              onChange={(e) => {
                setDetails(e.target.value, "message");
              }}
            />
            {formValidators.formErrors(validationErrors.message)}

            <button
              disabled={Boolean(loading)}
              className="formSubmitBtn"
              onClick={(e) => sendDetails(e)}
              style={{ cursor: Boolean(loading) ? "not-allowed" : "pointer" }}
            >
              {loading ? "Submitting..." : "SEND MESSAGE"}
            </button>
          </form>
        </div>
        <div className="servVdoRHS letsWorkLHS">
          <p className="whoWeContHeadTxt">
            Let's work together on your next Workload Migration Platform!
          </p>
          <p
            className="whoWeContHeadTxt"
            style={{ fontWeight: "normal", fontSize: 12 }}
          >
            Call us for immediate support to this number
          </p>
          <p className="whoWeContHeadTxt cp" onClick={handlePhoneNumberClick}>
            {appConstants.phoneNumber}
          </p>
          <div className="socialIcContServOut">
            <div
              className="socialIcContServ"
              onClick={() => {
                handleSendEmail();
              }}
            >
              <LocalPostOffice fontSize="small" className="socialIconServ" />
            </div>
            <div
              className="socialIcContServ"
              onClick={() => {
                openWhatsApp();
              }}
            >
              <WhatsApp fontSize="small" className="socialIconServ" />
            </div>
            <div
              className="socialIcContServ"
              onClick={() => {
                window.open(appConstants.linkedIn, "_blank");
              }}
            >
              <LinkedIn fontSize="small" className="socialIconServ" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRequestQuote: (requestData, onResponse) => {
      dispatch(createRequestQuote(requestData, onResponse));
    },
  };
};

export default connect(null, mapDispatchToProps)(DisRec);
