const appColors = {
  primaryColor: "#11aee7",
  secondaryColor: "#27405a",
  neutralYellow: "#ffd433",
  whiteColor: "#fff",
  blackColor: "rgba(12, 12, 12, 0.918)",
  smokyBlack: "rgba(0, 0, 0, 0.795)",
  greyishblack: "#262626",
  transparentBlue: "rgb(201, 218, 224)",
  secondaryDark: "#00142f",
  secondaryDarkOverlay: "#00142fbb",
  transparentOverlay: "rgba(0, 88, 170, 0.1)",
  transparentOverlayNavy: "rgba(0, 3, 170, 0.1)",
  navyOverlay: "#0348a3a6",
  tranparentBlack: "rgba(15, 15, 15, 0.856)",
  transparentOverlayCyan: "rgba(5, 34, 128, 0.726)",
  greenOverlay: "#0fb61d96",
  purpleOverlay: "rgba(128, 0, 128, 0.644)",
  primaryOveryLay: "#03455e9f",
  whiteOverlay: "rgba(255, 255, 255, 0.589)",
  red: "#f82121",
  palePrimary: "#eef8fc",
  palePrimaryLight: "#b8e8fa",
  primaryMedium: "#88d9f6",
  primaryExtraMedium: "#59caf3",
  primarySemi: "#29bbef",
  primarySemiBold: "#10a1d6",
  primaryExtraBold: "#0c7da6",
  primaryUltraBold: "#095977",
  primaryDark: "#053647",
  primaryExtraDark: "#021218",
  paleSecondary: "#edf2f7",
  paleSecondaryLight: "#cad8e8",
  secondaryMedium: "#a6bfd8",
  secondaryExtraMedium: "#82a5c9",
  secondarySemi: "#5f8bba",
  secondarySemiBold: "#4572a0",
  secondaryExtraBold: "#36597d",
  secondaryUltraBold: "#273f59",
  secondaryExtraDark: "#021218",
  purple: "#be65c1",
  grey: "grey",
};

export default appColors;
