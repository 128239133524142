import React from "react";
import appImages from "../../assets";
import "../../styles/layoutSass/home/base.scss";
import "../../styles/layoutSass/about/base.scss";
import "../../styles/layoutSass/career/base.scss";
import appColors from "../../utils/themes/colorThemes";
import {
  AssignmentInd,
  AttachFile,
  Badge,
  BusinessCenter,
  CheckCircle,
  ChevronRight,
  FormatListBulleted,
  LinkedIn,
  ListAlt,
  LocalPolice,
  LocalPostOffice,
  Notes,
  Place,
  PlaylistAdd,
  TextSnippet,
  Verified,
  WarningAmberOutlined,
  WhatsApp,
  WorkspacePremium,
} from "@mui/icons-material";
import { useState } from "react";
import formValidators from "../../utils/commonFunctions/formValidators";
import Toast from "../../components/Toast";
import Select from "react-select";
import { getJobsDetails, getJobsList } from "../../redux/actions/app.actions";
import { connect } from "react-redux";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import NoDataFound from "../../components/NoDataFound";
import { useRef } from "react";
import { applyJob } from "../../redux/actions/form.actions";
import { useNavigate, useParams } from "react-router";
import { Fragment } from "react";
import appConstants from "../../utils/appStrings/appConstants";
import openWhatsApp from "../../utils/commonFunctions/openWhatsapp";

const initialDetails = {
  name: "",
  email: "",
  mobile: "",
  position: "",
  message: "",
  resume: "",
};
const initialErrors = {
  name: false,
  email: false,
  mobile: "",
  position: false,
  message: false,
  resume: false,
};

export const CareerDetPage = (props) => {
  const routeParams = useParams();
  const jobId = routeParams?.jobId;

  const [careerDetails, setCareerDetails] = useState(initialDetails);
  const [validationErrors, setValdiationErrors] = useState(initialErrors);
  const [jobDet, setJobDet] = useState(false);
  const nameRef = useRef(null);
  const [refresh, setrefresh] = useState(false);
  const selectRef = useRef();

  const [jobList, setJobsList] = useState(false);

  const [fullJobsList, setFullJobsList] = useState(false);
  const [resMsg, setResMsg] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const setDetails = (value, key) => {
    setCareerDetails({ ...careerDetails, [key]: value });
    setValdiationErrors({ ...validationErrors, [key]: false });
  };
  const sendDetails = (e) => {
    e.preventDefault();
    const requestData = validateFields();

    if (requestData) {
      setLoading(true);

      props.applyJob(
        requestData,
        (res) => {
          const response = res;
          if (Number(response.statusCode) === 200) {
            setResMsg({
              icon: <CheckCircle style={{ color: appColors.whiteColor }} />,
              msg: response.message,
              bgColor: appColors.greenOverlay,
            });
            setTimeout(() => {
              setResMsg(false);
            }, 3000);
            setValdiationErrors(initialErrors);
            setCareerDetails(initialDetails);
            selectRef.current.value = "";
            setrefresh(!refresh);
            setLoading(false);
          } else {
            setResMsg({
              icon: (
                <WarningAmberOutlined style={{ color: appColors.whiteColor }} />
              ),
              msg: "Oops! Something went wrong!",
              bgColor: appColors.red,
            });
            setTimeout(() => {
              setResMsg(false);
            }, 2000);
            setLoading(false);
          }
        },
        false,
        false
      );
    } else {
      setResMsg({
        icon: <WarningAmberOutlined style={{ color: appColors.whiteColor }} />,
        msg: "Check the details entered",
        bgColor: appColors.red,
      });
      setTimeout(() => {
        setResMsg(false);
      }, 2000);
      setLoading(false);
    }
  };
  const handleSendMail = () => {
    const email = appConstants.mail; // Replace with the recipient's email address
    const subject = "Request for quote"; // Replace with the desired subject line
    const body = "Enter your subject for the mail"; // Replace with the desired email body

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  const validateFields = () => {
    let formData = new FormData();
    let errors = { ...validationErrors };

    if (formValidators.checkEmpty(careerDetails.name)) {
      errors.name = "Enter name";
      formData = undefined;
    } else {
      errors.name = false;
      formData?.append("candidate_name", careerDetails.name);
    }
    if (formValidators.checkEmpty(careerDetails.resume)) {
      errors.resume = "Choose resume";
      formData = undefined;
    } else {
      errors.resume = false;
      formData?.append("candidate_resume", careerDetails.resume);
    }
    if (formValidators.checkEmpty(careerDetails.mobile)) {
      errors.mobile = "Enter mobile";
      formData = undefined;
    } else if (!formValidators.checkMobile(careerDetails.mobile)) {
      errors.mobile = "Enter valid mobile";
      formData = undefined;
    } else {
      errors.mobile = false;
      formData?.append("mobile", careerDetails.mobile);
    }
    if (formValidators.checkEmpty(careerDetails.email)) {
      errors.email = "Enter email";
      formData = undefined;
    } else if (!formValidators.checkEmail(careerDetails.email)) {
      errors.email = "Enter valid email";
      formData = undefined;
    } else {
      errors.email = false;
      formData?.append("email", careerDetails.email);
    }

    if (formValidators.checkEmpty(careerDetails.position)) {
      errors.position = "Enter position";
      formData = undefined;
    } else {
      errors.position = false;
      formData?.append("job_id", careerDetails.position?.job_id);
    }
    if (formValidators.checkEmpty(careerDetails.message)) {
      errors.message = "Enter message";
      formData = undefined;
    } else if (!formValidators.checkLength(careerDetails.message, 10)) {
      errors.message = "Message should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.message = false;
      formData?.append("message", careerDetails.message);
    }
    setValdiationErrors(errors);
    return formData;
  };

  const handleChange = (selectedOption) => {
    setCareerDetails({ ...careerDetails, position: selectedOption });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected
        ? appColors.secondaryColor
        : appColors.secondaryColor,
      padding: 20,
    }),
    control: () => ({
      display: "flex",
      backgroundColor: "#283646a4",
      maxHeight: 35,
      fontSize: 14,
      color: appColors.whiteColor,
      // paddingTop: careerDetails.position ? 0 : 5,
    }),
    input: () => ({
      fontSize: 14,
      color: appColors.whiteColor,
      height: 35,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {
        ...provided,
        opacity,
        transition,
        color: "white",
        paddingTop: 15,
      };
    },
  };

  const getJobsLists = () => {
    props.getJobsList(
      null,
      (res) => {
        const response = res;

        if (Number(response?.statusCode) === 200) {
          let jobDatas = [];
          let jobFiltDatas = [];

          response.data.forEach((element) => {
            let singleJobData = { ...element };
            singleJobData.label = element?.job_title;
            singleJobData.value = element?.job_id;
            if (element?.job_id !== jobId) {
              jobFiltDatas = [...jobDatas, singleJobData];
            }
            jobDatas = [...jobDatas, singleJobData];
          });
          setJobsList(jobFiltDatas);
          setFullJobsList(jobDatas);
        } else {
          setJobsList([]);
        }
      },
      false,
      false
    );
  };
  const getJobsDetails = () => {
    props?.getJobsDetails &&
      props.getJobsDetails(
        { jobId },
        (res) => {
          const response = res;

          if (Number(response?.statusCode) === 200) {
            setJobDet(response?.data);
          } else {
            setJobDet([]);
          }
        },
        false,
        false
      );
  };
  React.useEffect(() => {
    getJobsLists();
    getJobsDetails();
  }, [refresh]);
  return (
    <div className="mainContentSection">
      <Toast
        showToast={Boolean(resMsg)}
        data={resMsg}
        closeToast={() => setResMsg(undefined)}
      />
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"Careers"}</h1>

          <p className="subNavAbout">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; About
          </p>
        </div>
      </div>
      <div className="centerGrid80 careerInfosCont">
        <div className="careerDescSocCont">
          <div className="leftMenuCont">
            <LinkedIn
              className="careerDescSocIcon"
              onClick={() => {
                window.open(
                  "https://in.linkedin.com/company/avancerpi",
                  "_blank"
                );
              }}
              style={{ cursor: "pointer" }}
            />
            <LocalPostOffice
              className="careerDescSocIcon"
              onClick={() => {
                handleSendMail();
              }}
            />

            <WhatsApp
              className="careerDescSocIcon"
              onClick={() => {
                openWhatsApp();
              }}
            />
          </div>
          <div className="careerDescRContent">
            {!Boolean(jobDet) ? (
              <SkeletonTheme
                baseColor={"#eee"}
                height="20px"
                width="calc(95%)"
                borderRadius={2.5}
              >
                <Skeleton className="careerDescLoader" />
              </SkeletonTheme>
            ) : typeof jobDet !== "object" ? (
              <h5 className="careerDescTitle">Invalid Data</h5>
            ) : (
              <h5 className="careerDescTitle">{jobDet?.job_title}</h5>
            )}
            <img
              src={appImages.career.careerDescBg}
              alt="careerDesc"
              className="careerDescBg"
            />
            {!Boolean(jobDet) ? (
              <SkeletonTheme
                baseColor={"#eee"}
                height="200px"
                width="calc(95%)"
                borderRadius={2.5}
              >
                <Skeleton className="careerDescLoader" />
              </SkeletonTheme>
            ) : typeof jobDet !== "object" ? (
              <h5 className="careerDescTitle">Invalid Data</h5>
            ) : (
              <Fragment>
                <span className="careerDescTitCont">
                  <Notes className="jobInnerDescIcon" />
                  <h5 className="similarJobs">Job Description</h5>
                </span>

                <p className="careerDescDet">{jobDet?.job_description}</p>

                <span className="careerDescTitCont top10margin">
                  <LocalPolice className="jobInnerDescIcon" />

                  <h5 className="similarJobs">Key Responsibilities</h5>
                </span>

                <ul className="careerDescListCont">
                  {jobDet &&
                    jobDet?.job_resp &&
                    [...JSON.parse(jobDet?.job_resp)].map((keyRes, i) => {
                      return (
                        <li className="descListSingle" key={i}>
                          <img
                            className="careerDescListLogo"
                            src={appImages.appImages.appArrowLogoBlack}
                            alt="avancerLogo"
                          />

                          <p className="careerDescDet">{keyRes}</p>
                        </li>
                      );
                    })}
                </ul>

                <span className="careerDescTitCont top10margin">
                  <Badge className="jobInnerDescIcon" />
                  <h5 className="similarJobs ">Experience</h5>
                </span>

                <p className="careerDescDet">{jobDet?.experience}</p>

                <span className="careerDescTitCont top10margin">
                  <Verified className="jobInnerDescIcon" />
                  <h5 className="similarJobs ">Skillset</h5>
                </span>

                <ul className="careerDescListCont">
                  {jobDet &&
                    jobDet?.skillset &&
                    [...JSON.parse(jobDet?.skillset)].map((keyRes, i) => {
                      return (
                        <li className="descListSingle" key={i}>
                          <img
                            className="careerDescListLogo"
                            src={appImages.appImages.appArrowLogoBlack}
                            alt="avancerLogo"
                          />

                          <p className="careerDescDet">{keyRes}</p>
                        </li>
                      );
                    })}
                </ul>
                <span className="careerDescTitCont top10margin">
                  <FormatListBulleted className="jobInnerDescIcon" />
                  <h5 className="similarJobs ">
                    Required Qualification{" "}
                    <span style={{ color: "red" }}>*</span>
                  </h5>
                </span>

                <ul className="careerDescListCont">
                  {jobDet &&
                    jobDet?.req_qual &&
                    [...JSON.parse(jobDet?.req_qual)].map((keyRes, i) => {
                      return (
                        <li className="descListSingle" key={i}>
                          <img
                            className="careerDescListLogo"
                            src={appImages.appImages.appArrowLogoBlack}
                            alt="avancerLogo"
                          />

                          <p className="careerDescDet">{keyRes}</p>
                        </li>
                      );
                    })}
                </ul>

                <span className="careerDescTitCont top10margin">
                  <PlaylistAdd className="jobInnerDescIcon" />
                  <h5 className="similarJobs ">Prefered Qualification</h5>
                </span>
                <ul className="careerDescListCont">
                  {jobDet &&
                    jobDet?.pref_qual &&
                    [...JSON.parse(jobDet?.pref_qual)].map((keyRes, i) => {
                      return (
                        <li className="descListSingle" key={i}>
                          <img
                            className="careerDescListLogo"
                            src={appImages.appImages.appArrowLogoBlack}
                            alt="avancerLogo"
                          />

                          <p className="careerDescDet">{keyRes}</p>
                        </li>
                      );
                    })}
                </ul>

                <span className="careerDescTitCont top10margin">
                  <Place className="jobInnerDescIcon" />
                  <h5 className="similarJobs">Location</h5>
                </span>
                <p className="careerDescDet">{jobDet?.job_location}</p>
              </Fragment>
            )}
          </div>

          <div className="similarJobsWinCont">
            <h5 className="similarJobs">Similar openings</h5>
            <div className="simJobUnd"></div>
            {!Boolean(jobList) ? (
              <div className="skeletonSimilar">
                <SkeletonTheme baseColor={"#eee"}>
                  <Skeleton
                    style={{
                      height: "calc(100vw / 5.1)",
                      width: "calc(100vw / 5.1)",
                    }}
                  />
                  <Skeleton
                    style={{
                      height: "calc(100vw / 5.1)",
                      width: "calc(100vw / 5.1)",
                    }}
                  />
                  <Skeleton
                    style={{
                      height: "calc(100vw / 5.1)",
                      width: "calc(100vw / 5.1)",
                    }}
                  />
                </SkeletonTheme>
              </div>
            ) : Array.isArray(jobList) && jobList.length !== 0 ? (
              <div className="careerDetailsCont">
                {jobList.map((job, i) => {
                  return (
                    <div className="jobDetCard" key={i}>
                      <div className="jobDetCardFill">
                        <div className="jobDetailsCont">
                          <div className="jobTitleCont">
                            <div className="flexCenter">
                              <BusinessCenter className="jobIcon" />
                              <p className="jobCardCnt">0{i + 1}.</p>
                            </div>

                            <h1 className="jobTitle">{job?.job_title}</h1>
                          </div>
                          <div className="jobRoleDetCont">
                            <ul className="jobListCont">
                              <li className="jobInnListCont">
                                <div className="jobListTitle">
                                  <TextSnippet className="jobDetIcon" />
                                  <h5 className="jobDetTitle">Role</h5>

                                  <h5 className="jobDetTitle">&nbsp;:</h5>
                                  <p className="roleDesc">
                                    {job?.job_description}
                                  </p>
                                </div>
                              </li>
                              <li className="jobInnListCont">
                                <div className="jobListTitle">
                                  <WorkspacePremium className="jobDetIcon" />
                                  <h5 className="jobDetTitle">Qual.</h5>
                                  <h5 className="jobDetTitle">:</h5>
                                  <p className="roleDesc">
                                    {job?.qualification}
                                  </p>
                                </div>
                              </li>
                            </ul>

                            <button
                              className="applyNowBtn"
                              onClick={() => {
                                navigate(`/CareerDetails/${job?.job_id}`);
                              }}
                            >
                              Apply Now
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="jobDetailsCont">
                        <div className="jobTitleCont">
                          <div className="flexCenter">
                            <BusinessCenter className="jobIcon" />
                            <p className="jobCardCnt">0{i + 1}.</p>
                          </div>

                          <h1 className="jobTitle">{job?.job_title}</h1>
                        </div>
                        <div className="jobRoleDetCont">
                          <ul className="jobListCont">
                            <li className="jobInnListCont">
                              <div className="jobListTitle">
                                <AssignmentInd className="jobDetIcon" />
                                <h5 className="jobDetTitle">Type&nbsp;</h5>

                                <h5 className="jobDetTitle"> :</h5>
                                <p className="roleDesc">{job?.job_type}</p>
                              </div>
                            </li>
                            <li className="jobInnListCont">
                              <div className="jobListTitle">
                                <ListAlt className="jobDetIcon" />
                                <h5 className="jobDetTitle">
                                  Exp.&nbsp;&nbsp;
                                </h5>
                                <h5 className="jobDetTitle">:</h5>
                                <p className="roleDesc">{job?.experience}</p>
                              </div>
                            </li>

                            <li className="jobInnListCont">
                              <div className="jobListTitle">
                                <Place className="jobDetIcon" />
                                <h5 className="jobDetTitle">
                                  Loc.&nbsp;&nbsp;
                                </h5>
                                <h5 className="jobDetTitle"> :</h5>
                                <p className="roleDesc">Work from home</p>
                              </div>
                            </li>
                          </ul>

                          {/* <button className="detailsBtn">View more</button> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <NoDataFound
                message={"Oh oh ! Currently we are not having more openings"}
                customStyle={{
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  paddingTop: 25,
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="similarJobsMobHeadCont">
        <h5 className="similarJobs">Similar openings</h5>
        <div className="simJobUnd"></div>
      </div>
      <div className="similarJobsMobCont">
        {!Boolean(jobList) ? (
          <div className="skeletonSimilar">
            <SkeletonTheme baseColor={"#eee"}>
              <Skeleton
                style={{
                  height: "calc(100vw / 5.1)",
                  width: "calc(100vw / 5.1)",
                }}
              />
              <Skeleton
                style={{
                  height: "calc(100vw / 5.1)",
                  width: "calc(100vw / 5.1)",
                }}
              />
              <Skeleton
                style={{
                  height: "calc(100vw / 5.1)",
                  width: "calc(100vw / 5.1)",
                }}
              />
            </SkeletonTheme>
          </div>
        ) : Array.isArray(jobList) && jobList.length !== 0 ? (
          <div className="careerDetailsCont">
            {jobList.map((job, i) => {
              return (
                <div className="jobDetCard" key={i}>
                  <div className="jobDetCardFill">
                    <div className="jobDetailsCont">
                      <div className="jobTitleCont">
                        <div className="flexCenter">
                          <BusinessCenter className="jobIcon" />
                          <p className="jobCardCnt">0{i + 1}.</p>
                        </div>

                        <h1 className="jobTitle">{job?.job_title}</h1>
                      </div>
                      <div className="jobRoleDetCont">
                        <ul className="jobListCont">
                          <li className="jobInnListCont">
                            <div className="jobListTitle">
                              <TextSnippet className="jobDetIcon" />
                              <h5 className="jobDetTitle">Role</h5>

                              <h5 className="jobDetTitle">&nbsp;:</h5>
                              <p className="roleDesc">{job?.job_description}</p>
                            </div>
                          </li>
                          <li className="jobInnListCont">
                            <div className="jobListTitle">
                              <WorkspacePremium className="jobDetIcon" />
                              <h5 className="jobDetTitle">Qual.</h5>
                              <h5 className="jobDetTitle">:</h5>
                              <p className="roleDesc">{job?.qualification}</p>
                            </div>
                          </li>
                        </ul>

                        <button
                          className="applyNowBtn"
                          onClick={() => {
                            navigate(`/CareerDetails/${job?.job_id}`);
                          }}
                        >
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="jobDetailsCont">
                    <div className="jobTitleCont">
                      <div className="flexCenter">
                        <BusinessCenter className="jobIcon" />
                        <p className="jobCardCnt">0{i + 1}.</p>
                      </div>

                      <h1 className="jobTitle">{job?.job_title}</h1>
                    </div>
                    <div className="jobRoleDetCont">
                      <ul className="jobListCont">
                        <li className="jobInnListCont">
                          <div className="jobListTitle">
                            <AssignmentInd className="jobDetIcon" />
                            <h5 className="jobDetTitle">Type&nbsp;</h5>

                            <h5 className="jobDetTitle"> :</h5>
                            <p className="roleDesc">{job?.job_type}</p>
                          </div>
                        </li>
                        <li className="jobInnListCont">
                          <div className="jobListTitle">
                            <ListAlt className="jobDetIcon" />
                            <h5 className="jobDetTitle">Exp.&nbsp;&nbsp;</h5>
                            <h5 className="jobDetTitle">:</h5>
                            <p className="roleDesc">{job?.experience}</p>
                          </div>
                        </li>

                        <li className="jobInnListCont">
                          <div className="jobListTitle">
                            <Place className="jobDetIcon" />
                            <h5 className="jobDetTitle">Loc.&nbsp;&nbsp;</h5>
                            <h5 className="jobDetTitle"> :</h5>
                            <p className="roleDesc">Work from home</p>
                          </div>
                        </li>
                      </ul>

                      {/* <button className="detailsBtn">View more</button> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <NoDataFound
            message={"Oh oh ! Currently we are not having more openings"}
            customStyle={{
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingTop: 25,
              width: "78%",
              maxWidth: "78%",
              margin: "0 auto",
            }}
          />
        )}
      </div>
      <div className="serviceVdoCont">
        <div
          className="servVdoLHS "
          style={{
            backgroundImage: `url(https://www.workitdaily.com/media-library/man-talks-to-his-boss-about-his-career-goals.jpg?id=30022265&width=2000&height=1500&quality=85&coordinates=0%2C0%2C100%2C0)`,
            backgroundColor: appColors.whiteColor,
            backgroundSize: "100% 100%",
          }}
        ></div>
        <div className="servVdoTestRHS">
          <div className="  servTestiCont">
            <div className="applyUpperLine"></div>
            <p
              className="whoWeHeadTxt"
              style={{
                marginTop: 10,
                color: appColors.whiteColor,
                fontSize: 22,
              }}
            >
              Apply to join our <p style={{ color: "crimson" }}>team</p>{" "}
            </p>
            <form className="top10margin">
              <div className="contactConents">
                <div className="carInputCont">
                  <p className="inputPlaceHolder" style={{ marginTop: 25 }}>
                    Name
                  </p>
                  <input
                    ref={nameRef}
                    className="newLetterInput careerInput"
                    value={careerDetails.name}
                    onChange={(e) => {
                      setDetails(e.target.value, "name");
                    }}
                  />
                  {formValidators.formErrors(validationErrors.name)}
                </div>

                <div className="carInputCont">
                  <p className="inputPlaceHolder" style={{ marginTop: 25 }}>
                    Email
                  </p>
                  <input
                    className="newLetterInput careerInput"
                    value={careerDetails.email}
                    onChange={(e) => {
                      setDetails(e.target.value, "email");
                    }}
                  />
                  {formValidators.formErrors(validationErrors.email)}
                </div>
              </div>

              <div className="contactConents">
                <div className="carInputCont">
                  <p className="inputPlaceHolder" style={{ marginTop: 25 }}>
                    Phone
                  </p>
                  <input
                    className="newLetterInput careerInput"
                    value={careerDetails.mobile}
                    onChange={(e) => {
                      setDetails(e.target.value, "mobile");
                    }}
                  />
                  {formValidators.formErrors(validationErrors.mobile)}
                </div>

                {jobList && Array.isArray(jobList) && (
                  <div className="carInputCont">
                    <p className="inputPlaceHolder" style={{ marginTop: 25 }}>
                      Apply position
                    </p>

                    <Select
                      onChange={(opt) => {
                        handleChange(opt);
                      }}
                      value={careerDetails?.position}
                      inputValue={
                        !formValidators.checkEmpty(careerDetails?.position)
                          ? [...jobList][
                              [...jobList].findIndex(
                                (el, i) => el.job_id === careerDetails?.position
                              )
                            ]?.job_title
                          : ""
                      }
                      ref={selectRef}
                      options={fullJobsList}
                      styles={customStyles}
                      placeholder="Choose position"
                    />
                    {formValidators.formErrors(validationErrors.position)}
                  </div>
                )}
              </div>

              <textarea
                className="newLetterInput careerInput careerInputDesc"
                style={{ marginTop: 25, minHeight: 100 }}
                placeholder="Your message here"
                value={careerDetails.message}
                onChange={(e) => {
                  setDetails(e.target.value, "message");
                }}
              />
              {formValidators.formErrors(validationErrors.message)}

              <div className="uploadBtnWrapper">
                <button
                  className="uploadFab"
                  id="uploadingFab"
                  onMouseOver={() => {
                    document.getElementById(
                      "uploadingFab"
                    ).style.backgroundColor = appColors.primaryOveryLay;
                  }}
                  onMouseOut={() => {
                    document.getElementById(
                      "uploadingFab"
                    ).style.backgroundColor = appColors.secondaryColor;
                  }}
                >
                  <AttachFile style={{ fontSize: "15px" }} /> Your resume
                </button>
                {!formValidators.checkEmpty(careerDetails?.resume) ? (
                  <p className="resumeFileTxt">{careerDetails?.resume?.name}</p>
                ) : (
                  <p className="resumeFileTxt">No file chosen</p>
                )}
                {formValidators.formErrors(validationErrors.resume)}

                <input
                  type="file"
                  multiple={true}
                  onChange={(e) => {
                    setValdiationErrors({ ...validationErrors, resume: false });
                    setDetails(e.target.files[0], "resume");
                  }}
                />
              </div>

              <button
                disabled={Boolean(loading)}
                className="formSubmitBtn"
                onClick={(e) => sendDetails(e)}
                style={{ cursor: Boolean(loading) ? "not-allowed" : "pointer" }}
              >
                {loading ? "Submitting..." : "SUBMIT"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobsList: (requestData, onResponse) => {
      dispatch(getJobsList(requestData, onResponse));
    },
    getJobsDetails: (requestData, onResponse) => {
      dispatch(getJobsDetails(requestData, onResponse));
    },
    applyJob: (requestData, onResponse) => {
      dispatch(applyJob(requestData, onResponse));
    },
  };
};

export default connect(null, mapDispatchToProps)(CareerDetPage);
