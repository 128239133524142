import React, { Fragment, useState } from "react";
import "../../../styles/layoutSass/home/base.scss";
import "../../../styles/layoutSass/about/base.scss";
import "../../../styles/layoutSass/services/base.scss";
import "../../../styles/layoutSass/home/login/_login.scss";
import "../../../styles/layoutSass/services/_hcitest.scss";

import appColors from "../../../utils/themes/colorThemes";
import appImages from "../../../assets";
import {
  ArrowDropDown,
  CheckCircle,
  ChevronRight,
  LinkedIn,
  LocalPostOffice,
  WarningAmberOutlined,
  WhatsApp,
} from "@mui/icons-material";
import formValidators from "../../../utils/commonFunctions/formValidators";
import Toast from "../../../components/Toast";
import { connect } from "react-redux";
import { createRequestQuote } from "../../../redux/actions/form.actions";
import { ArrowDropUp } from "@material-ui/icons";
import appConstants from "../../../utils/appStrings/appConstants";
import openWhatsApp, {
  handlePhoneNumberClick,
} from "../../../utils/commonFunctions/openWhatsapp";

// const slides = [
//   {
//     city: "Paris",
//     country: "France",
//     img: appImages.successStories.success1,
//     customer: "YesBank",
//     quotes: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
//   },
//   {
//     city: "Singapore",
//     img: appImages.successStories.success2,
//     customer: "YesBank",
//     quotes: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
//   },
//   {
//     city: "Prague",
//     country: "Czech Republic",
//     img: appImages.successStories.success3,
//     customer: "Redhat",
//     quotes: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.`,
//   },
// ];

const initialDetails = {
  name: "",
  email: "",
  mobile: "",
  sub: "",
  message: "",
};
const initialErrors = {
  name: false,
  email: false,
  mobile: "",
  sub: false,
  message: false,
};

const WorkLoadMigration = (props) => {
  const effPoints = [
    {
      title: "In-depth review",
      desc:
        "We perform a thorough review of your existing infrastructure to identify the most suitable migration strategy and plan.Our team conducts a thorough review of your existing infrastructure, identifying the most suitable migration strategy and plan that aligns with global standards, industry white papers, and best practices",
    },
    {
      title: "Migration event planning",
      desc:
        "Our team carefully plans migration events to minimize disruption and ensure a smooth transition.Migration event planning based on industry best practices. We meticulously plan migration events, taking into account factors such as scheduling, resources, and dependencies to minimize disruption and ensure a smooth transition",
    },
    {
      title: "Commitment to design principles and global standards",
      desc:
        "We adhere to industry best practices and our design principles, prioritizing high availability, security, scalability, simple manageability, performance, and recoverability throughout the migration process",
    },

    {
      title: "Post-migration support and optimization",
      desc:
        "Our services extend beyond the migration itself, offering post-migration support and optimization to ensure your infrastructure continues to meet your needs and align with industry standards",
    },
    {
      title: "Risk assessment and mitigation",
      desc:
        "Our team assesses potential risks and implements mitigation strategies to address any challenges that may arise during the migration process",
    },
  ];
  const accordianData = [
    {
      title: "Comprehensive migration solutions",
      desc:
        "We provide an end-to-end service that covers various migration scenarios, including Physical-to-Virtual, Virtual-to-Virtual, and Any-to-Any",
    },
    {
      title: "Customized migration plans",
      desc:
        "Our team develops tailored migration plans that consider your unique requirements and infrastructure.We develop tailored migration plans based on your unique requirements, infrastructure, industry standards, and best practices",
    },
    {
      title: "Minimal downtime",
      desc:
        "We employ best practices and follow our design principles to ensure minimal downtime during the migration process.Provide training and documentation to enable the IT team to manage and maintain the HCI environment effectively",
    },
    {
      title: "Pre and post-migration tasks",
      desc:
        "Our services include pre and post-migration tasks such as testing, validation, and optimization to ensure a successful migration",
    },

    {
      title: "Thorough testing and validation",
      desc:
        "We perform rigorous testing and validation to ensure optimal performance, stability, and alignment with industry standards and best practices",
    },
    {
      title: "Security and compliance",
      desc:
        "Our team prioritizes security and compliance, safeguarding your data and applications throughout the migration process",
    },
  ];
  const [accordIndex, setAccordIndex] = useState(0);

  const designConsiderations = [
    {
      title: "Assess the current environment",
      designDesc:
        "Analyze the existing infrastructure, workloads, and performance requirements to determine the suitability of an HCI solution for the migration",
    },
    {
      title: "Capacity planning",
      designDesc:
        "Estimate the required resources, such as CPU, memory, storage, and network capacity, for the migrated workloads on the HCI platform",
    },
    {
      title: "Migration strategy",
      designDesc:
        "Develop a migration plan that considers factors like application dependencies, downtime requirements, and rollback options",
    },
    {
      title: "Validation and testing",
      designDesc:
        "Conduct thorough testing and validation to ensure that the migrated workloads perform as expected on the HCI platform and adhere to the design considerations",
    },

    {
      title: "Risk assessment and mitigation",
      designDesc:
        "Identify potential risks and challenges during the migration process and develop strategies to mitigate them",
    },
    {
      title: "Monitoring and optimization",
      designDesc:
        "Implement monitoring tools to track the performance of the migrated workloads on the HCI platform and optimize the infrastructure as needed",
    },
    {
      title: "Training and knowledge transfer",
      designDesc:
        "Provide training and documentation to enable the IT team to manage and maintain the HCI environment effectively",
    },
  ];

  const [quoteDetails, setQuoteDetails] = useState(initialDetails);
  const [validationErrors, setValdiationErrors] = useState(initialErrors);

  const handleSendEmail = () => {
    const email = appConstants.mail; // Replace with the recipient's email address
    const subject = "Request for quote"; // Replace with the desired subject line
    const body = "Hi avancerPI solutions, Shall we connect!"; // Replace with the desired email body

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoLink;
  };

  const setDetails = (value, key) => {
    setQuoteDetails({ ...quoteDetails, [key]: value });
    setValdiationErrors({ ...validationErrors, [key]: false });
  };

  const [resMsg, setResMsg] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const sendDetails = (e) => {
    e.preventDefault();
    const requestData = validateFields();
    if (requestData) {
      if (requestData) {
        setLoading(true);
        props.createRequestQuote(
          requestData,
          (res) => {
            const response = res;
            if (Number(response.statusCode) === 200) {
              setResMsg({
                icon: <CheckCircle style={{ color: appColors.whiteColor }} />,
                msg: response.message,
                bgColor: appColors.greenOverlay,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 3000);
              setValdiationErrors(initialErrors);
              setQuoteDetails(initialDetails);
              setLoading(false);
            } else {
              setResMsg({
                icon: (
                  <WarningAmberOutlined
                    style={{ color: appColors.whiteColor }}
                  />
                ),
                msg: "Oops! Something went wrong!",
                bgColor: appColors.red,
              });
              setTimeout(() => {
                setResMsg(false);
              }, 2000);
              setLoading(false);
            }
          },
          false,
          false
        );
      }
    } else {
      setResMsg({
        icon: <WarningAmberOutlined style={{ color: appColors.whiteColor }} />,
        msg: "Check the details entered",
        bgColor: appColors.red,
      });
      setTimeout(() => {
        setResMsg(false);
      }, 2000);
      setLoading(false);
    }
  };

  const validateFields = () => {
    let formData = new FormData();
    let errors = { ...validationErrors };

    if (formValidators.checkEmpty(quoteDetails.name)) {
      errors.name = "Enter name";
      formData = undefined;
    } else {
      errors.name = false;
      formData?.append("name", quoteDetails.name);
    }

    if (formValidators.checkEmpty(quoteDetails.mobile)) {
      errors.mobile = "Enter mobile";
      formData = undefined;
    } else if (!formValidators.checkMobile(quoteDetails.mobile)) {
      errors.mobile = "Enter valid mobile";
      formData = undefined;
    } else {
      errors.mobile = false;
      formData?.append("mobile", quoteDetails.mobile);
    }
    if (formValidators.checkEmpty(quoteDetails.email)) {
      errors.email = "Enter email";
      formData = undefined;
    } else if (!formValidators.checkEmail(quoteDetails.email)) {
      errors.email = "Enter valid email";
      formData = undefined;
    } else {
      errors.email = false;
      formData?.append("email", quoteDetails.email);
    }
    if (formValidators.checkEmpty(quoteDetails.sub)) {
      errors.sub = "Enter subject";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.sub, 10)) {
      errors.sub = "Subject should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.sub = false;
      formData?.append("subject", quoteDetails.sub);
    }
    if (formValidators.checkEmpty(quoteDetails.message)) {
      errors.message = "Enter message";
      formData = undefined;
    } else if (!formValidators.checkLength(quoteDetails.message, 10)) {
      errors.message = "Message should be atleast 10 characters";
      formData = undefined;
    } else {
      errors.message = false;
      formData?.append("message", quoteDetails.message);
    }
    setValdiationErrors(errors);
    return formData;
  };

  return (
    <div className="mainContentSection">
      <Toast
        showToast={Boolean(resMsg)}
        data={resMsg}
        closeToast={() => setResMsg(undefined)}
      />
      <div className="aboutUsMainBanner">
        <div className="aboutUsMainCont centerGrid80">
          <h1 className="aboutUsHeading">{"Workload Migration"}</h1>

          <p className="subNavAbout mt10">
            Home &nbsp;
            <ChevronRight fontSize="large" className="subNavArrow" />
            &nbsp; Services
          </p>
        </div>
      </div>
      <div className="centerGrid80 aboutWhoWeAreCont mt20">
        <div className="hciContHeadCont">
          <p className="whoWeHeadTxt">Workload </p>
          <p className="whoWeHeadTxt">Migration</p>

          <div className="whoWeUnderline" />
        </div>
        <div className="whoWeContCont">
          <p className="whoWeContTxt">
            Our workload migration services help organizations seamlessly
            transition their critical business applications and data to new
            platforms. We specialize in various migration types, including
            Physical-to-Virtual, Virtual-to-Virtual, and Any-to-Any. By adhering
            to design principles and best practices, our team ensures minimal
            downtime and a seamless migration process.
            <br />
            <br />
            Our workload migration services enable organizations to transition
            their critical business applications and data to new platforms
            seamlessly. We specialize in various migration scenarios, including
            Physical-to-Virtual, Virtual-to-Virtual, and Any-to-Any. Our team
            follows industry best practices, adheres to global standards and
            white papers, and incorporates a comprehensive set of considerations
            to ensure minimal downtime and a seamless migration process.
            <br />
            <br />
            By taking into account all relevant considerations, including
            industry best practices, global standards, white papers, and the
            unique needs of your organization, we deliver exceptional workload
            migration services that optimize your infrastructure and drive
            business growth
          </p>
        </div>
      </div>
      <div className="serviceVdoCont desConOutCont mt20">
        <div className="centerGrid90 desConCont">
          <div className="servVdoLHS bgTrans">
            <p className="smallBoldTitles textGrey">Under Workload Migration</p>

            <p className="whoWeHeadTxt textPrim">Main key features</p>
            <p className="whoWeHeadTxt textPrim">and benefits</p>
            <br />
            <img
              src={appImages.services.hciTestBanner}
              style={{
                width: "70%",
                height: "40%",
              }}
              alt="avancerImg"
            />
          </div>

          <div className="homeTestiContCont">
            {accordianData.map((accord, i) => {
              return (
                <Fragment>
                  <div
                    className="aboutUsAccorCont"
                    onClick={() => {
                      setAccordIndex(i);
                    }}
                  >
                    <p className="accordHeadText">{accord?.title}</p>
                    {accordIndex !== i ? (
                      <ArrowDropDown className="aboutAccArrow" />
                    ) : (
                      <ArrowDropUp className="aboutAccArrow" />
                    )}
                  </div>
                  {accordIndex === i && (
                    <div className="accordContCont">
                      <p>{accord?.desc}</p>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
      <div className="homeAboutCont centerGrid80">
        <div className="homeAboutImgCont">
          <img
            className="homeAboutImg"
            alt="avancerAboutImg"
            src={appImages.homeImages.Aboutus}
          />
        </div>
        <div className="homeAboutContentsCont mt20">
          <div className="homeAboutLine"></div>
          <p className="homeAboutTxt aboutHeadingHome">
            Unique Approach
            <br />
            and Expertise
          </p>
          <div className="aboutDetailsHomeCont">
            <span>
              <ul className="homeAboutList">
                {effPoints.map((content, i) => {
                  return (
                    <li className="aboutInnList" key={i}>
                      <CheckCircle className="aboutListIcon" />
                      <p className="aboutInnContent">
                        <span style={{ fontWeight: "700" }}>
                          {content?.title}
                        </span>{" "}
                        - {content?.desc}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </span>
            <p className="aboutHomeContent" style={{ fontSize: 18 }}>
              By taking into account all relevant considerations, including
              industry best practices, global standards, white papers, and the
              unique needs of your organization, we deliver exceptional workload
              migration services that optimize your infrastructure and drive
              business growth.
            </p>
          </div>
        </div>
      </div>

      <div
        className="approachContainer"
        style={{
          marginTop: 30,
          marginBottom: 30,
        }}
      >
        <p
          className="aboutHeadingHome textAlignCenter"
          style={{
            color: appColors.secondaryColor,
          }}
        >
          Workload Migrations with
          <br />
          HCI Design Considerations
        </p>
        <div
          className="approachContent centerGrid60"
          style={{
            marginTop: 5,
            marginBottom: 5,
          }}
        >
          <p className="whoWeContTxt textAlignCenter">
            When planning workload migrations with HCI design considerations in
            mind, it's essential to address the following aspects.
            <br />
          </p>
        </div>
      </div>
      <div className="considCardCont">
        <div className="considCardBox">
          {[...designConsiderations].map((card, i) => {
            return (
              <div className="considCardItem" key={i}>
                <span className="considCardTitle">
                  <div className="considCardBg"></div>

                  <img
                    className="iconArrowCard"
                    src={appImages.appImages.appArrowLogo}
                    alt="avancerLogo"
                  />
                  <div className="considCardItemTitle">{card?.title}</div>
                  <p className="considCardDesc">{card?.designDesc}</p>
                </span>
              </div>
            );
          })}
        </div>
      </div>

      {/* <CitiesSlider slides={slides} /> */}

      <div
        className="centerGrid60 servFormCont mt20"
        style={{ justifyContent: "space-between" }}
      >
        <div className="servVdoLHS servFormLHS">
          <form>
            <input
              className="newLetterInput"
              placeholder="Name *"
              value={quoteDetails.name}
              onChange={(e) => {
                setDetails(e.target.value, "name");
              }}
            />
            {formValidators.formErrors(validationErrors.name)}

            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Email address *"
              value={quoteDetails.email}
              onChange={(e) => {
                setDetails(e.target.value, "email");
              }}
            />

            {formValidators.formErrors(validationErrors.email)}
            <input
              style={{ marginTop: 25 }}
              className="newLetterInput"
              placeholder="Mobile *"
              value={quoteDetails.mobile}
              onChange={(e) => {
                setDetails(e.target.value, "mobile");
              }}
            />
            {formValidators.formErrors(validationErrors.mobile)}

            <input
              className="newLetterInput"
              style={{ marginTop: 25 }}
              placeholder="Subject *"
              value={quoteDetails.sub}
              onChange={(e) => {
                setDetails(e.target.value, "sub");
              }}
            />
            {formValidators.formErrors(validationErrors.sub)}

            <textarea
              className="newLetterInput"
              style={{ marginTop: 25, minHeight: 100 }}
              placeholder="Your message *"
              value={quoteDetails.message}
              onChange={(e) => {
                setDetails(e.target.value, "message");
              }}
            />
            {formValidators.formErrors(validationErrors.message)}

            <button
              disabled={Boolean(loading)}
              className="formSubmitBtn"
              onClick={(e) => sendDetails(e)}
              style={{ cursor: Boolean(loading) ? "not-allowed" : "pointer" }}
            >
              {loading ? "Submitting..." : "SEND MESSAGE"}
            </button>
          </form>
        </div>
        <div className="servVdoRHS letsWorkLHS">
          <p className="whoWeContHeadTxt">
            Let's work together on your next Workload Migration Platform!
          </p>
          <p
            className="whoWeContHeadTxt"
            style={{ fontWeight: "normal", fontSize: 12 }}
          >
            Call us for immediate support to this number
          </p>
          <p className="whoWeContHeadTxt cp" onClick={handlePhoneNumberClick}>
            {appConstants.phoneNumber}
          </p>
          <div className="socialIcContServOut">
            <div
              className="socialIcContServ"
              onClick={() => {
                handleSendEmail();
              }}
            >
              <LocalPostOffice fontSize="small" className="socialIconServ" />
            </div>
            <div
              className="socialIcContServ"
              onClick={() => {
                openWhatsApp();
              }}
            >
              <WhatsApp fontSize="small" className="socialIconServ" />
            </div>
            <div
              className="socialIcContServ"
              onClick={() => {
                window.open(appConstants.linkedIn, "_blank");
              }}
            >
              <LinkedIn fontSize="small" className="socialIconServ" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createRequestQuote: (requestData, onResponse) => {
      dispatch(createRequestQuote(requestData, onResponse));
    },
  };
};

export default connect(null, mapDispatchToProps)(WorkLoadMigration);
