import React, { Fragment } from "react";
import AppNavigations from "./AppNavigations";
import DashboardNavigations from "./DashboardNavigations";
import SiteNav from './appnavigations/SiteNav.js'

const MainNavigation = () => {
  return (
    <SiteNav/>
  );
};

export default MainNavigation;
