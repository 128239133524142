import React, { useState } from "react";
import HeaderMobile from "./HeaderMobile";
import HeaderWindow from "./HeaderWindow";

import "../../styles/componentSass/header/base.scss";

const Header = (props) => {
  const { isMobileDevice, currentWidth, mobOpen } = props;
  return isMobileDevice ? (
    <HeaderMobile mobOpen={mobOpen} />
  ) : (
    <HeaderWindow currentWidth={currentWidth} mobOpen={mobOpen} />
  );
};

export default Header;
