import React, { useState } from "react";
import "../../styles/layoutSass/home/login/_login.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import appImages from "../../assets";
const CSELogin = () => {
  const [openPass, setOpenPass] = useState(false);
  return (
    <div className="loginCont">
      <div className="loginImgSec">
        <div className="loginImgInnCont">
          <div className="loginImgInnCirle">
            <img
              className="loginCseAvatar"
              src={appImages.appImages.appArrowLogo}
              alt="avancerProfile"
            />
          </div>
        </div>
      </div>
      <div className="loginContents">
        <div className="loginInnCont">
          <p className="loginTxt">Login</p>
          <div className="loginInputPassCont">
            <input className="loginInput" placeholder="User name" />
          </div>
          <div className="loginInputPassCont">
            <input
              className="loginInput"
              placeholder="Password"
              type={openPass ? "text" : "password"}
            />
            <div
              className="passIconCont"
              onClick={() => setOpenPass(!openPass)}
            >
              {/* {openPass ? (
                <div className="passIcon">🙊</div>
              ) : (
                <div className="passIcon">🙈</div>
              )} */}
              {openPass ? (
                <Visibility className="passIcon" />
              ) : (
                <VisibilityOff className="passIcon" />
              )}
            </div>
          </div>
          <div className="loginBtn">
            <p>Sign In</p>
          </div>
          <p className="forgotPassTxt">Forgot Password?</p>
        </div>
      </div>
    </div>
  );
};

export default CSELogin;
