import React from "react";

import { SentimentSatisfied } from "@mui/icons-material";

import appColors from "../utils/themes/colorThemes";
const NoDataFound = ({ message, customStyle }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "50vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...customStyle,
      }}
    >
      <SentimentSatisfied
        style={{
          color: appColors.primarySemiBold,
        }}
      />
      <p
        style={{
          color: appColors.secondaryExtraBold,
          fontWeight: "bold",
          marginLeft: 5,
          maxWidth: "50%",
        }}
      >
        {message ? message : "No data found"}
      </p>
    </div>
  );
};

export default NoDataFound;
