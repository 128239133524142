import React, { useState } from "react";
import Carousel from "react-spring-3d-carousel";
import uuidv4 from "uuid";
import { config } from "react-spring";
import "./styles.css";
import { useRef } from "react";
import appImages from "../../assets";
import appColors from "../../utils/themes/colorThemes";

const Slider = (data) => {
  const [state, setState] = useState({
    goToSlide: 0,
    offsetRadius: 2,
    showNavigation: true,
    config: config.gentle,
    autoplay: true,
    autoplaySpeed: 1000,
  });
  const clientLogos = [
    appImages.logos.nutanixshort,
    appImages.logos.redhat,
    appImages.logos.vmware,
    appImages.logos.aws,
    appImages.logos.nutanixshort,
    appImages.logos.redhat,
    appImages.logos.vmware,
    appImages.logos.aws,
  ];

  let slideArr = [];
  if (clientLogos && Array.isArray(clientLogos)) {
    clientLogos.forEach((el, i) =>
      slideArr.push({
        key: uuidv4(),
        content: (
          <div
            style={{
              backgroundColor: appColors.palePrimary,
              height: 100,
              width: 150,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundBlendMode: "overlay",
              borderRadius: "5px",
              boxShadow: `0px 0px 10px ${appColors.secondaryExtraMedium}`,
              cursor: "pointer",
              marginTop: -100,
            }}
          >
            <img src={el} alt="1" style={{ width: 100, height: 150 }} />
          </div>
        ),
      })
    );
  }

  const slides = [...slideArr].map((slide, index) => {
    return {
      ...slide,
      onClick: () => setState({ ...state, goToSlide: index }),
    };
  });

  React.useEffect(() => {
    const interval = setInterval(() => {
      const stateSlide = state.goToSlide;

      if (stateSlide === slides.length - 1) {
        setState({ ...state, goToSlide: 0 });
      } else {
        setState({ ...state, goToSlide: stateSlide + 1 });
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  });

  const sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  // const onChangeInput = (e) => {
  //   setState({ ...state, [e.target.name]: parseInt(e.target.value, 10) || 0 });
  // };
  return (
    <div
      style={{
        width: "80%",
        height: "17rem",
        margin: "0 4.5rem",
      }}
    >
      <Carousel
        slides={slides}
        goToSlide={state.goToSlide}
        offsetRadius={state.offsetRadius}
        showNavigation={state.showNavigation}
        animationConfig={state.config}
        dynamicHeight={true}
        ref={sliderRef}
        {...settings}
      ></Carousel>
    </div>
  );
};

export default Slider;
