import * as Types from "../root.types";

const initialState = {
  selectedLanguage: null,
  isMenuOpen: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SELECTED_LANGUAGE:
      return { ...state, selectedLanguage: action.payload };
    case Types.IS_MENU_OPEN:
      return { ...state, isMenuOpen: action.payload };
    default:
      return state;
  }
};

export default authReducer;
